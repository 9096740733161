import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PrivateRoute } from 'helpers/private-route';
import { PublicRoute } from 'helpers/public-route';
import { Layout, VideoConference } from 'components';

import {
  Registration,
  Privacy,
  Home,
  Category,
  ResetPassword,
  ForgottenPassword,
  Search,
  NotFound,
  Product,
  RecommendationsProduct,
  AccountSettings,
  Contacts,
  ContinueWatching,
  AccountConfirmation,
  Impersonate,
  SignIn,
  Typography,
  About,
  Help,
  Terms,
  SavedList,
  AllProducts,
  RecommendationsList,
} from 'pages';
import { MAIN_CATEGORY } from 'types';
import { FILM_TYPES, TV_TYPES } from '../types';
import { EventPage } from 'pages/event-page/event-page';
import RecommendationsEvent from 'pages/recommendations-collection/recommendations-collection';
import { TwoFactorAuth } from 'pages/two-factor-auth/two-factor-auth';

export const ScrollToTop = () => {
  const location = useLocation();
  const { pathname, hash } = location;

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};

export enum Routes {
  HOME = '/',
  REGISTRATION = '/registration',
  SIGN_IN = '/sign-in',
  PRIVACY = '/privacy',
  CONTACTS = '/contacts',
  FORGOTTEN_PASSWORD = '/forgotten-password',
  CATEGORY = '/category',
  MOVIES = '/movies',
  TV = '/tv',
  PRODUCT = '/product',
  ACCOUNT_CONFIRMATION = '/confirmations',
  CONTINUE_WATCHING = '/continue-watching',
  SAVED_LISTS = '/saved-lists',
  SEARCH = '/search',
  VIDEO_CONFERENCE = '/video-conference',
  RECOMMENDATIONS_PRODUCT = '/recommendations/product',
  ACCOUNT_SETTINGS = '/account-settings',
  RECOMMENDATIONS_LIST = '/recommendations-list',
  IMPERSONATE = '/session/:sessionId/:redirect?',
  HELP = '/help',
  ABOUT = '/about',
  TERMS = '/terms',
  TYPOGRAPHY = '/typography',
  RESET_PASSWORD = '/passwords',
  EVENTS = '/events',
  TWO_FACTOR_AUTH = '/2fa',
  RECOMMENDATIONS_COLLECTION = '/collection-recommendation',
}

export const AppRoutes: React.FC<{}> = observer(() => {
  return (
    <Layout>
      <Switch>
        <PrivateRoute path={`${Routes.MOVIES}/all`}>
          <AllProducts types={FILM_TYPES} title="Browse movies" />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.TV}/all`}>
          <AllProducts types={TV_TYPES} title="Browse television" />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.MOVIES}/:genre?`}>
          <Category root={MAIN_CATEGORY.MOVIES} />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.TV}/:genre?`}>
          <Category root={MAIN_CATEGORY.TV} />
        </PrivateRoute>

        <PrivateRoute path={Routes.ACCOUNT_SETTINGS}>
          <AccountSettings />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.PRODUCT}/:productId`}>
          <Product />
        </PrivateRoute>

        <PrivateRoute path={Routes.SEARCH}>
          <Search />
        </PrivateRoute>

        <PublicRoute path={Routes.REGISTRATION}>
          <Registration />
        </PublicRoute>

        <PublicRoute path={Routes.SIGN_IN}>
          <SignIn />
        </PublicRoute>

        <PublicRoute path={Routes.FORGOTTEN_PASSWORD}>
          <ForgottenPassword />
        </PublicRoute>

        <PrivateRoute path={Routes.CONTINUE_WATCHING}>
          <ContinueWatching />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.SAVED_LISTS}/:id?`}>
          <SavedList />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.RECOMMENDATIONS_LIST}/:id?`}>
          <RecommendationsList />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.EVENTS}/:name`}>
          <EventPage />
        </PrivateRoute>

        <PrivateRoute path={`${Routes.RECOMMENDATIONS_COLLECTION}/:recommendationId`}>
          <RecommendationsEvent />
        </PrivateRoute>

        <Route path={Routes.TYPOGRAPHY} component={Typography} />
        <Route path={`${Routes.RESET_PASSWORD}/:token/edit`} component={ResetPassword} />
        <Route path={`${Routes.ACCOUNT_CONFIRMATION}/:token`} component={AccountConfirmation} />

        <Route
          exact
          path={`${Routes.RECOMMENDATIONS_PRODUCT}/:productId/:recommendationId?`}
          component={RecommendationsProduct}
        />

        <Route path={`${Routes.VIDEO_CONFERENCE}/:code`} component={VideoConference} />
        <Route path={Routes.PRIVACY} component={Privacy} />
        <Route path={Routes.TWO_FACTOR_AUTH} component={TwoFactorAuth} />
        <Route path={Routes.ABOUT} component={About} />
        <Route path={Routes.HELP} component={Help} />
        <Route path={Routes.TERMS} component={Terms} />
        <Route path={Routes.CONTACTS} component={Contacts} />
        <Route path={Routes.IMPERSONATE} component={Impersonate} />
        <PrivateRoute path={Routes.HOME}>
          <Home />
        </PrivateRoute>

        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
});
