import React, { useEffect } from 'react';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useStore } from 'store';

import { start, chipmunk, cookie, getSession, queryStringParams } from 'utils';

export const Impersonate: React.FC<{}> = observer(() => {
  const store = useStore();
  const { sessionStore, toastStore, routing } = store;
  const { sessionId, redirect } = useParams<{ sessionId: string; redirect: string }>();

  useEffect(() => {
    (async () => {
      await start(store);

      try {
        const adminUiRef = get(queryStringParams(document.location), 'ref');

        sessionStore.clear();

        cookie.set('sessionId', sessionId);
        if (adminUiRef) cookie.set('adminUiRef', adminUiRef);

        chipmunk.updateConfig({ headers: { 'Session-Id': sessionId } });
        await getSession(); // raises an error when unsuccessful without trying to get a public session

        sessionStore.loadSession(true);
      } catch (err) {
        toastStore.error('Impersonate failed');
      } finally {
        const [target, id] = redirect ? redirect?.split(':') : [];

        switch (target) {
          case 'product':
            routing.push(`/product/${id}`);
            break;

          default:
            routing.push('/');
        }
      }
    })();
  });

  return null;
});
