import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Routes } from 'routes';
import { chipmunk, displayError, start } from 'utils';
import { countries } from '@mediafellows/tuco/dist/lib/localization';
import { ButtonSpinner, FormBox, InterestSelection } from 'components';

export const genderMap = {
  'Mr.': 'Male',
  'Ms.': 'Female',
  'Mx.': 'Diverse',
};

export const salutations = Object.keys(genderMap);

const formDataTemplate = {
  // user_salutation: 'Mr.',
  // user_gender: 'Male',
  user_title: null,
  // user_interests: ['TV Shows', 'Movies', 'Action', 'Adventure', 'Crime', 'Cult', 'Drama'],
  // user_country_id: 'VAT',
  // user_territory_rep: 'Tyler Caldwell',
  // user_terms_accepted: 'true',
  user_phone_0_label: 'Work',
  user_phone_1_label: 'Home', // optional home phone.. not required!!
  user_address_zip_code: '--',
  user_address_city: '--',
  user_address_street: '--',
  // user_address_country_id: 'AFG',
  // user_responsible_user_id: 1229926,
  organization_legal_address_zip_code: '--',
  organization_legal_address_city: '--',
  organization_legal_address_street: '--',
  // organization_legal_address_country_id: 'AFG',
  organization_legal_address_label: 'Legal',
  organization_classification: 'Other',
  organization_legal_entity: 'Other',
  // user_first_name: 'Yanko',
  // user_last_name: 'Shterev',
  // user_email: 'yshterev@gmail.com',
  // user_phone_0_number: '+359884895232',
  // organization_name: 'asdasd',
  // user_function: 'Programmer',
  // user_notes: 'message for rep demo',
  // captcha: '14',
};

export const Registration: React.FC<{}> = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const store = useStore();
  const {
    basicStore: { salesContacts, isLoadingSalesContacts },
  } = store;

  const { toastStore, routing } = useStore();
  const [interests, setInterests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await start(store);
      await store.basicStore.loadSalesContacts();
    })();
  }, [store]);

  const submitCallback = async (data): Promise<void> => {
    const { user_responsible_user_id, user_salutation, user_address_country_id } = data;
    const { first_name, last_name } = salesContacts.find((item) => item.id === Number(user_responsible_user_id));

    const body = {
      ...formDataTemplate,
      ...data,
      user_responsible_user_id: Number(user_responsible_user_id),
      user_interests: interests,
      user_gender: genderMap[user_salutation],
      user_territory_rep: `${first_name} ${last_name}`,
      organization_legal_address_country_id: user_address_country_id,
    };

    setIsLoading(true);

    try {
      await chipmunk.action('um.registration', 'create', {
        body,
        params: { rawRequest: true },
        headers: { 'Session-Id': null },
      });
      toastStore.success('Thank you! Your application has been submitted successfully.');
      routing.push(Routes.SIGN_IN);
    } catch {
      setIsLoading(false);
      toastStore.error('Failed to submit application. Please try again or contact us.');
    }
  };

  return (
    <FormBox colClass="col-12 col-md-10">
      <div className="row">
        <div className="col-12">
          <h3 className="mb-10 mt-13">Create Account</h3>
          <h4>Account Info</h4>
        </div>
      </div>
      <form onSubmit={handleSubmit(submitCallback)} noValidate={true}>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 mt-3">
          <div className="col mb-3">
            <label htmlFor="company" className="form-label">
              Company
            </label>
            <input
              id="company"
              placeholder="Ex. Lionsgate"
              type="text"
              {...register('organization_name', {
                required: true,
                minLength: 2,
                maxLength: 255,
              })}
              className={cx('form-control form-control-lg', { 'is-invalid': errors?.organization_name })}
            />

            {displayError(errors?.organization_name, 'Company')}
          </div>

          <div className="col mb-3">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              id="title"
              placeholder="Ex. VP of communication"
              type="text"
              {...register('user_function', {
                minLength: 2,
                maxLength: 128,
              })}
              className={cx('form-control form-control-lg', { 'is-invalid': errors?.user_function })}
            />

            {displayError(errors?.user_function, 'Job title')}
          </div>

          <div className="col mb-3">
            <label htmlFor="salutation" className="form-label">
              Salutation
            </label>
            <select
              id="salutation"
              defaultValue={salutations[0]}
              className={cx('form-select form-select-lg d-inline-block', {
                'is-invalid': errors?.user_salutation,
              })}
              aria-label="Salutation"
              {...register('user_salutation')}
            >
              {salutations.map((profile) => (
                <option key={profile} value={profile}>
                  {profile}
                </option>
              ))}
            </select>
          </div>

          <div className="col mb-3">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <input
              id="firstName"
              placeholder="Ex. John"
              type="text"
              {...register('user_first_name', {
                required: true,
                minLength: 2,
                maxLength: 32,
              })}
              className={cx('form-control form-control-lg', { 'is-invalid': errors?.user_first_name })}
            />

            {displayError(errors?.user_first_name, 'First name')}
          </div>

          <div className="col mb-3">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input
              id="lastName"
              placeholder="Ex. Smith"
              type="text"
              {...register('user_last_name', {
                required: true,
                minLength: 2,
                maxLength: 32,
              })}
              className={cx('form-control form-control-lg', { 'is-invalid': errors?.user_last_name })}
            />

            {displayError(errors?.user_last_name, 'Last name')}
          </div>

          <div className="col mb-3">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              id="email"
              placeholder="Ex. johnsmith@isp.com"
              type="email"
              {...register('user_email', {
                required: true,
                minLength: 8,
              })}
              autoComplete={'email'}
              className={cx('form-control form-control-lg', { 'is-invalid': errors?.user_email })}
            />

            {displayError(errors?.user_email, 'Email')}
          </div>

          <div className="col mb-3">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              id="phone"
              placeholder="Ex. 906-273-1450"
              type="text"
              {...register('user_phone_0_number', {
                pattern: /^[0-9()+\-.]{5,}$/i,
                required: true,
              })}
              className={cx('form-control form-control-lg', { 'is-invalid': errors?.user_phone_0_number })}
            />

            {displayError(errors?.user_phone_0_number, 'Telephone')}
          </div>

          <div className="col mb-3">
            <label htmlFor="salesContact" className="form-label">
              Sales Contact
            </label>
            <select
              id="salesContact"
              disabled={isLoadingSalesContacts}
              defaultValue={''}
              className={cx('form-select form-select-lg', {
                'is-invalid': errors?.user_responsible_user_id,
              })}
              aria-label="Sales Contact *"
              {...register('user_responsible_user_id', { required: true })}
            >
              <option value="" disabled>
                Select sales contact
              </option>
              {salesContacts.map(({ id, first_name, last_name }) => (
                <option key={id} value={id}>
                  {first_name} {last_name}
                </option>
              ))}
            </select>

            {displayError(errors?.user_responsible_user_id, 'Sales contact')}
          </div>

          <div className="col mb-3">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <select
              id="country"
              defaultValue=""
              className={cx('form-select form-select-lg', { 'is-invalid': errors?.user_address_country_id })}
              aria-label="Country *"
              {...register('user_address_country_id', { required: true })}
            >
              <option value={''} disabled>
                Select country
              </option>
              {countries.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>

            {displayError(errors?.user_address_country_id, 'Country')}
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label htmlFor="optionalComments" className="form-label">
              Optional Comments
            </label>
            <textarea
              id="optionalComments"
              rows={2}
              placeholder={'Anything else we should know? (optional)'}
              {...register('user_notes', {
                maxLength: 250,
              })}
              className={cx('form-control form-control-lg', { 'is-invalid': errors?.user_notes })}
            />

            {displayError(errors?.user_notes, 'Message')}
          </div>
        </div>

        <div className="row">
          <InterestSelection state={[interests, setInterests]} />
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mt-3">
            <p>Humanity check</p>
            <img loading="lazy" className="img-fluid" alt="captcha" src="/static-assets/img/captcha.png" />
            <input
              className={cx('form-control mt-3', { 'is-invalid': errors?.captcha })}
              placeholder="Your answer *"
              type="text"
              {...register('captcha', {
                required: true,
                validate: (value: string) => {
                  if (value !== '14') return 'Wrong answer!';
                },
              })}
            />
            {displayError(errors?.captcha, 'Captcha')}
          </div>
          <div className="col-12 col-md-6 mb-3 mt-3">
            <p>Privacy policy</p>
            <div className="form-check">
              <input
                className={cx('form-check-input', { 'is-invalid': errors?.user_terms_accepted })}
                type="checkbox"
                value=""
                {...register('user_terms_accepted', {
                  required: true,
                })}
              />
              <label className="form-check-label" htmlFor="user_terms_accepted">
                I am authorized to act on behalf of the business. I accept the{' '}
                <Link target={'blank'} to={Routes.PRIVACY}>
                  Privacy Policy
                </Link>{' '}
                on behalf of both the business and myself as far as my consent is legally required. *
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="my-8" />
            <div className="d-flex justify-content-center">
              <div>
                <button type="submit" disabled={isLoading} className="btn btn-lg btn-outline-primary">
                  <ButtonSpinner title={'Request Access'} isLoading={isLoading} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormBox>
  );
});
