import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import { resizedUrl, ImgResizerSizes } from 'utils';
import './style.scss';
import { isArray } from 'lodash';

export type thumbText = {
  title: string;
  content?: string | string[];
};

export interface IThumbnailProps {
  className?: string;
  image?: string | Required<{ url: string }>;
  label?: string;
  assetType?: string;
  showPlay?: boolean;
  progress?: number;
  showProgress?: boolean;
  showOverlay?: boolean;
  text?: thumbText;
  scale?: ImgResizerSizes;
  onClick?: (e: any) => void;
}

export const Thumbnail: React.FC<IThumbnailProps> = observer((props) => {
  const { image, showOverlay = true, text, className, label, progress, showProgress, scale = 'xga', onClick } = props;

  let url = image?.['url'] ? image['url'] : image;
  url = image ? resizedUrl(url, { size: scale }) : '';
  const urlFallback = url || '/static-assets/img/placeholder.png';

  return (
    <div onClick={onClick}>
      <div className={cx('thumbnail', className, { 'thumbnail--overlay': showOverlay })}>
        <div style={{ backgroundImage: `url(${urlFallback})` }} className={cx('thumbnail__content')} />

        {label && (
          <div className="thumbnail__label bg-dark-blue text-white d-inline-block small fw-bold px-2">{label}</div>
        )}

        {showProgress && !isNaN(progress) && (
          <div className="thumbnail__video-progress">
            <div className="thumbnail__progress-bar" style={{ width: `${progress}%` }} />
          </div>
        )}
      </div>

      {text && (
        <div className="thumbnail__text">
          <div className={cx('thumbnail__text-title h4', { 'mb-0': !text.content })}>{text.title}</div>
          {text.content && !isArray(text.content) && <div className="thumbnail__text-content">{text.content}</div>}
          {text.content && isArray(text.content) && (
            <div className="thumbnail__text-content">
              {text.content.map((item, i) => (
                <span key={i} className="me-3">
                  {item}
                </span>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
