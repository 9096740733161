import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from 'components';
import { useStore } from 'store';
import { Modal } from 'types';
import './styles.scss';
import { useBaskets } from 'utils/hooks/use-baskets';
import { useBasketsProducts } from 'utils/hooks/use-basket-products';
import { chipmunk } from 'utils';

const SaveToListModal: React.FC<{ productId?: string }> = observer(({ productId }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const store = useStore();
  const { toastStore, modalStore, sessionStore } = store;
  const { basketData: baskets, isBasketsLoading, mutateBaskets } = useBaskets(sessionStore?.session?.user?.id);
  const { basketProducts } = useBasketsProducts(baskets);

  const handleCreate = (e) => {
    e.preventDefault();

    modalStore.hide(Modal.SAVE_TO_LIST, () => {
      modalStore.open(Modal.EDIT_LIST, { props: { openSaveToList: true, callbackProductId: productId } });
    });
  };

  const handleSaveToList = (isPresentInBasket: boolean, basketId: number) => {
    const params = {
      basket_id: basketId,
      product_ids: [Number(productId)],
    };

    if (isPresentInBasket) {
      chipmunk.run(
        async ({ action }) => {
          await action('pm.basket', 'remove_products', { params });
          toastStore.success('Removed from list');
          mutateBaskets();
        },
        () => {
          toastStore.error('Failed to remove item from saved list');
        },
      );

      return;
    }

    chipmunk.run(
      async ({ action }) => {
        await action('pm.basket', 'add_products', { params });
        toastStore.success('Added to list');
        mutateBaskets();
      },
      () => {
        toastStore.error('Failed to add item from saved list');
      },
    );
  };

  if (isBasketsLoading) return null;

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title"></h5>
        <div className="close" data-bs-dismiss="modal" aria-label="Close">
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="modal-body savetolist-modal px-7">
        <h4>Save To List</h4>
        <ul className="mt-4">
          {baskets.map(({ id, name }, index) => {
            const products = basketProducts[id];
            const isPresentInBasket = products?.some((product) => product.id === productId);

            return (
              <li key={index} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  role="button"
                  defaultChecked={isPresentInBasket}
                  onClick={() => handleSaveToList(isPresentInBasket, id)}
                />
                <span className="custom-link">{name}</span>
                {hoverIndex === index && <div className="savetolist-modal__selectedglow" />}
              </li>
            );
          })}

          <li onMouseEnter={() => setHoverIndex(baskets.length + 1)} onMouseLeave={() => setHoverIndex(null)}>
            <div className="d-flex justify-content-between">
              <a href="#" className="custom-link" onClick={handleCreate}>
                Create new list
              </a>
              <span className="savetolist-modal__plus">+</span>
            </div>

            {hoverIndex === baskets.length + 1 && <div className="savetolist-modal__selectedglow" />}
          </li>
        </ul>
      </div>
    </>
  );
});

export default SaveToListModal;
