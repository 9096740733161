import { get } from 'lodash';
import { chipmunk } from 'utils/chipmunk';
import { cookie } from 'utils/cookie';

export const getSession = async (): Promise<any> => {
  const params = !cookie.get('sessionId') ? { guest: '1' } : {};

  const session = (
    await chipmunk.action('um.session', 'full', {
      params,
      raw: true,
    })
  ).object;

  session.isPublic = session['@type'] === 'session/public';
  session.isRecommendation = session['@type'] === 'session/recommendation';
  session.isAuthenticated = session['@type'] === 'session';
  session.isEvent = session['@type'] === 'session/event';
  session.isAdmin = get(session, 'role.designation') === 'All';

  const sessionId = session.id;
  const roleId = get(session, 'role.id');
  const affiliationId = get(session, 'affiliation.id');

  cookie.set('sessionId', sessionId);
  cookie.set('roleId', roleId);
  cookie.set('affiliationId', affiliationId);

  chipmunk.updateConfig({
    headers: {
      'Session-Id': sessionId,
      'Role-Id': roleId,
      'Affiliation-Id': affiliationId,
    },
  });

  return session;
};
