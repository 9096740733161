import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { ButtonSpinner, Icon } from 'components';
import { useStore } from 'store';
import { Modal } from 'types';
import cx from 'classnames';
import { chipmunk } from 'utils';

const EditListModal: React.FC<{
  basket?: any;
  openSaveToList?: boolean;
  callbackProductId?: string;
  onSave?: () => void;
  mutateBaskets?: () => void;
}> = observer(({ basket, openSaveToList = false, callbackProductId, onSave = null, mutateBaskets = null }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const store = useStore();
  const { toastStore, modalStore } = store;

  const isEdit = !!basket;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!basket) return;
    setValue('listName', basket.name);
  }, [basket, setValue]);

  const submitCallback = async (data) => {
    const name = data?.listName;

    if (isEdit) {
      const newBasket = { ...basket, name };
      setIsLoading(true);
      await chipmunk.run(async (chipmunk) => {
        return chipmunk.action('pm.basket', 'member.update', {
          params: { basket_id: basket.id },
          body: newBasket,
        });
      });
      mutateBaskets?.();
      setIsLoading(false);

      toastStore.success('List updated');
    } else {
      setIsLoading(true);
      await chipmunk.action('pm.basket', 'create', {
        body: { name },
      });
      mutateBaskets?.();
      setIsLoading(false);

      toastStore.success('List created');
    }

    if (openSaveToList) {
      modalStore.hide(Modal.EDIT_LIST, () => {
        modalStore.open(Modal.SAVE_TO_LIST, { props: { productId: callbackProductId } });
      });
    } else {
      modalStore.hide(Modal.EDIT_LIST);
      onSave?.();
    }
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title"></h5>
        <div className="close" data-bs-dismiss="modal" aria-label="Close">
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="modal-body px-7">
        <form id="editListForm" noValidate={true} onSubmit={handleSubmit(submitCallback)}>
          <div className="form-group">
            {!isEdit && <p>Create new list</p>}
            {isEdit && <p>Edit list name</p>}
            <label htmlFor="list-name" className="form-label">
              List name
            </label>
            <input
              type="text"
              placeholder={isEdit ? 'Custom list title' : 'Ex. My new favorites'}
              {...register('listName', {
                required: true,
              })}
              className={cx('form-control', { 'is-invalid': errors?.listName })}
              id="list-name"
            />
            {errors?.listName && (
              <div className="invalid-feedback">
                {errors?.listName?.type === 'required' && 'List name is required.'}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-center">
        <div className="d-flex justify-content-center flex-column text-center">
          <button type="submit" form="editListForm" disabled={isLoading} className="btn btn-lg btn-outline-primary">
            <ButtonSpinner title={isEdit ? 'Save Changes' : 'Create'} isLoading={isLoading} />
          </button>
          <a href="#" className="custom-link my-4" data-bs-dismiss="modal" onClick={(e) => e.preventDefault()}>
            Cancel
          </a>
        </div>
      </div>
    </>
  );
});

export default EditListModal;
