import { action, observable, IObservableArray } from 'mobx';
import { tuco } from 'utils';
import { IGetPurposeGroupEntities } from '@mediafellows/tuco/dist/lib/tasks';
import { IResult } from '@mediafellows/chipmunk/dist/src/action';

export class BasicsStore {
  @observable public categories = [] as IObservableArray;
  @observable public languages = [] as IObservableArray;
  @observable public salesContacts = [] as IObservableArray;
  @observable public isLoadingSalesContacts = true;
  @observable public users = [] as IObservableArray;

  private basicsPromise: Promise<any>;
  private salesContactsPromise: Promise<any>;

  @action.bound
  public async loadBasics(reload = false) {
    if (this.basicsPromise && !reload) return this.basicsPromise;

    const promise = async () => {
      try {
        const data = (await tuco('gcuiBasics')) as IResult;
        const { categories = [], languages = [] } = data?.object || {};
        this.categories.replace(categories);
        this.languages.replace(languages);
      } catch {
        this.basicsPromise = null;
        console.log('failed to load basics');
      }
    };

    return (this.basicsPromise = promise());
  }

  @action.bound
  public async loadSalesContacts(reload = false) {
    if (this.salesContactsPromise && !reload) return this.salesContactsPromise;

    const promise = async () => {
      try {
        this.isLoadingSalesContacts = true;
        const data = await tuco('getPurposeGroupUsers', { purpose: 'sales_contacts' } as IGetPurposeGroupEntities);
        const { objects: contacts = [] } = data || {};

        this.salesContacts.replace(contacts);
      } catch (e) {
        this.salesContactsPromise = null;
        console.log('failed to load sales contacts');
      } finally {
        this.isLoadingSalesContacts = false;
      }
    };

    return (this.salesContactsPromise = promise());
  }

  @action.bound
  public resetPromises() {
    this.salesContactsPromise = null;
    this.basicsPromise = null;
  }
}
