import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Footer, Header, Stripes } from 'components';
import { useLocation } from 'react-router-dom';
import { Routes } from 'routes';
import { useStore } from 'store';
import './layout.scss';
import { General } from 'types';
import { useOffcanvasStore } from 'store/offcanvas-store';

const body = document.body;

const Layout = observer(({ children }) => {
  const store = useStore();
  const {
    homeStore: { heroMainImage },
  } = store;
  const offcanvas = useOffcanvasStore((state) => state.offcanvas);

  const location = useLocation();
  const { pathname } = location;

  const notInPath = (paths) => !paths.some((route) => pathname.includes(route));

  const showHeader = notInPath([
    Routes.TWO_FACTOR_AUTH,
    Routes.SIGN_IN,
    Routes.REGISTRATION,
    Routes.FORGOTTEN_PASSWORD,
    Routes.VIDEO_CONFERENCE,
    Routes.TYPOGRAPHY,
    'passwords',
  ]);

  const isHome = pathname === Routes.HOME;
  const showFooter = notInPath([Routes.VIDEO_CONFERENCE, Routes.TYPOGRAPHY]);
  const pageName = pathname.split('/')[1] || 'home';

  useEffect(() => {
    if (body.classList.contains(General.OFFCANVAS_ACTIVE)) {
      offcanvas?.hide();
      body.classList.remove(General.OFFCANVAS_ACTIVE);
    }
  }, [location, offcanvas]);

  return (
    <>
      {showHeader && <Header className={`header--${pageName}`} />}

      <div className={'layout flex-shrink-0 position-relative'}>
        {isHome && heroMainImage && (
          <div className="layout__home">
            <div
              className="layout__home-box"
              style={
                {
                  // backgroundImage: `url(${heroMainImage})`,
                }
              }
            ></div>

            <div className="layout__home-image-overlay" />
          </div>
        )}

        <div className="position-absolute overflow-hidden w-100 h-100">
          <Stripes>
            <div className="layout__glowmask" />
          </Stripes>
        </div>

        <div className="position-relative">
          <div className="d-flex flex-column layout__content">
            <div className="flex-1">{children}</div>
            {showFooter && <Footer className={`footer--${pageName}`} />}
          </div>
        </div>
      </div>
    </>
  );
});

export default Layout;
