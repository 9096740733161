import React from 'react';
import { round, map, join, endsWith } from 'lodash';
import { FILM_TYPES, TV_TYPES } from 'types';

export const videoProgress = (duration: number, lastTimecode: number): number => {
  if (duration <= 0 || lastTimecode <= 0) return 0;
  return round((100 / duration) * lastTimecode, 2);
};

export const csv = (o, k) => join(map(o, k), ', ');
export const deepFind = (collection, term, taxonomy = 'id', childTreeName = 'children', maxDepth = 10, depth = 0) => {
  let match;

  if (!collection) {
    return false;
  }

  for (const subject of collection) {
    if (subject[taxonomy] === term) {
      match = subject;
    } else if (depth <= 10) {
      match = deepFind(subject[childTreeName], term, taxonomy, childTreeName, maxDepth, depth + 1);
    }

    if (match) {
      return match;
    }
  }

  return false;
};

export const isVideo = (asset): boolean => {
  return /^video/i.test(asset?.classification);
};

export const isImage = (asset): boolean => {
  return /^image/i.test(asset?.classification);
};

export const isDocument = (asset): boolean => {
  return /^document/i.test(asset?.classification);
};

export const formatProductInfo = (product, categories) => {
  const layer = product?.default_layer;

  if (!layer) {
    return [];
  }

  const detailArray = [];

  if (endsWith(product['@type'], 'season')) {
    // label which season
    detailArray.push('Season ' + product.sequence_number);
  } else if (endsWith(product['@type'], 'episode')) {
    // label which episode
    detailArray.push('Episode ' + product.sequence_number);
  }

  if (endsWith(product['@type'], 'season') && product.episodes_count) {
    // a season should show an episode count
    detailArray.push(
      product.episodes_count === 1 ? product.episodes_count + ' Episode' : product.episodes_count + ' Episodes',
    );
  } else if (!endsWith(product['@type'], 'season') && !endsWith(product['@type'], 'program') && product.seasons_count) {
    // a non-season product should show season count if present
    detailArray.push(
      product.seasons_count === 1 ? product.seasons_count + ' Season' : product.seasons_count + ' Seasons',
    );
  } else if (product.episodes_count) {
    // a non-season product with an episode count should show episode count (e.g., miniseries)
    detailArray.push(
      product.episodes_count === 1 ? product.episodes_count + ' Episode' : product.episodes_count + ' Episodes',
    );
  } else if (layer.year_of_production) {
    // otherwise, attempt to show the year of production
    detailArray.push(product.default_layer.year_of_production);
  }

  if (product && layer && layer.categories && layer.categories.length) {
    // category name
    const category = deepFind(categories, layer.categories[0].id);

    if (category) {
      detailArray.push({ category });
    }
  }

  return detailArray;
};

export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const displayError = (error, field, message = '') => {
  if (!error) return null;

  return (
    <div className="invalid-feedback">
      {error.type === 'required' && `${field} is required.`}
      {error.type === 'maxLength' && `${field} is too long.`}
      {error.type === 'minLength' && `${field} is too short.`}
      {error.type === 'validate' && `${message || error.message}`}
      {error.type === 'pattern' && (`${error.message}` || `${field} entry is invalid. ${message}`)}
    </div>
  );
};

export const parseDataSize = (size) => {
  if (size / 1024 < 1) {
    return `${size} B`;
  } else if (size / 1024 / 1024 < 1) {
    return `${(size / 1024).toFixed(2)} kB`;
  } else if (size / 1024 / 1024 / 1024 < 1) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  } else {
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  }
};

export const scrollIntoView = (element, offset = 0): void => {
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

export const isEqualArray = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const getType = (type) => {
  if (!type) return '';

  if (TV_TYPES.includes(type.toLowerCase())) {
    return 'Television';
  }

  if (FILM_TYPES.includes(type.toLowerCase())) {
    return 'Movie';
  }

  return '';
};

export const getThumbText = (product) => {
  const { default_layer, title } = product;
  const { year_of_production, rating, product_type } = default_layer || {};

  const type = getType(product_type);
  const content = [year_of_production, rating];

  if (type) {
    content.push(type);
  }

  return {
    title,
    content,
  };
};

export const yearNow = new Date().getUTCFullYear();
export const defaultYearStart = 1950;
export const defaultYearEnd = yearNow + 2;
export const defaultRuntimeStart = 0;
export const defaultRuntimeEnd = 180;
