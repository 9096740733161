import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { getThumbText, start } from 'utils';
import { FeaturedThumbnail, HorizontalThumbs, Loading } from 'components';
import { Routes } from 'routes';
import { Modal } from 'types';
import { Link } from 'react-router-dom';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import './style.scss';

export const Home: React.FC = observer(() => {
  const store = useStore();
  const {
    sessionStore: { user },
    routing,
    homeStore: { continueWatching, heroProducts, recommendedProducts, specialEventsProducts },
    modalStore: { open },
  } = store;

  useEffect(() => {
    (async () => {
      await start(store);
      store.homeStore.loadHeroProducts();
      store.homeStore.loadRecommendedProducts();
      // await store.homeStore.loadAppAssets();
      store.homeStore.loadSpecialEvents();
      store.homeStore.loadContinueWatching();
    })();
  }, [store]);

  const handleThumbClick = useCallback(
    (_: number, { id }) => {
      routing.push(`${Routes.PRODUCT}/${id}`);
    },
    [routing],
  );

  const handleContinueWatching = useCallback(
    (_: number, product) => {
      open(Modal.PREVIEW_ASSETS, {
        modalOptions: { backdrop: 'static' },
        props: { product },
        sizeClass: 'modal-xl',
      });
    },
    [open],
  );

  if (!user)
    return (
      <div className="mt-15">
        <Loading />
      </div>
    );

  return (
    <>
      <div className="home-page container-fluid--custom mt-13">
        <div className="home-page__hero">
          <div className="row">
            <div className="col-6">
              <h3>Welcome, {user?.first_name}</h3>
            </div>
            {!!heroProducts.length && (
              <div className="row h-100 my-3">
                <div className="col-12 h-100">
                  <Swiper
                    className="home-swiper h-100"
                    modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    effect={'coverflow'}
                    centeredSlides={true}
                    breakpoints={{
                      768: {
                        coverflowEffect: {
                          rotate: 0,
                          stretch: 0,
                          depth: 300,
                          modifier: 1,
                          slideShadows: true,
                        },
                        slidesPerView: 2,
                        initialSlide: 1,
                      },
                    }}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                  >
                    {heroProducts.map((product, index) => {
                      return (
                        <SwiperSlide className="home-swiper__slide" key={`hero-${index}`}>
                          <div
                            className="home-swiper__slide-bg d-flex flex-column justify-content-end p-4"
                            style={{
                              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), url(${product.preview_image?.url})`,
                            }}
                          >
                            <div>
                              <Link to={`${Routes.PRODUCT}/${product.product.id}`}>
                                <button className="btn btn-outline-primary">View More</button>
                              </Link>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}
          </div>
          <div className="row d-flex align-items-end h-100">
            <div className="d-none col-12 col-lg-6">
              We hope that you will enjoy exploring our catalogue, where you can watch trailers and screeners from any
              browser, whether desktop or mobile. You can create and share lists of your favorites, or browse packages
              recommended to you personally.
            </div>
          </div>
        </div>
        <div className="d-none row row-cols-1 row-cols-sm-2 mb-10">
          {heroProducts.map((item, index) => {
            if (index === 0) return null;

            return (
              <div className="col" key={`featured-${index}`}>
                <FeaturedThumbnail
                  showCategory={true}
                  item={item?.product}
                  imgUrl={item?.preview_image?.url}
                  onClick={() => handleThumbClick(index, { id: item?.product?.id })}
                />
              </div>
            );
          })}
        </div>

        {Boolean(continueWatching.length) && (
          <>
            <div className="row mt-6">
              <div className="d-flex align-items-baseline">
                <h3 className="text-capitalize mb-2">Continue Watching</h3>
                <Link className="mx-3 view-all-link" to={`${Routes.CONTINUE_WATCHING}`}>
                  View All
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <HorizontalThumbs
                  showVideoProgress={true}
                  scale="vga"
                  onClick={handleContinueWatching}
                  items={continueWatching.slice(0, 7)}
                  texts={continueWatching.slice(0, 7).map(getThumbText)}
                />
              </div>
            </div>
          </>
        )}

        {Boolean(specialEventsProducts.length) &&
          specialEventsProducts.map((group, index) => {
            return (
              <React.Fragment key={index}>
                <div className="row mt-6" key={group.id}>
                  <div className="col-12">
                    <div className="row">
                      <div className="d-flex align-items-baseline">
                        <h3 className="text-capitalize mb-2">{group.name}</h3>
                        <Link className="mx-3 view-all-link" to={`${Routes.EVENTS}/${group.name}`}>
                          View All
                        </Link>
                      </div>
                    </div>
                    <HorizontalThumbs
                      scale="vga"
                      onClick={handleThumbClick}
                      items={group.products}
                      texts={group.products.map(getThumbText)}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}

        {Boolean(recommendedProducts.length) && (
          <div className="row mt-6">
            <div className="col-12">
              <div className="row">
                <div className="col-8">
                  <h3 className="text-capitalize mb-3">Recommended</h3>
                </div>
                <div className="col-4 text-end">
                  <Link to={Routes.RECOMMENDATIONS_LIST}>View All</Link>
                </div>
              </div>
              <HorizontalThumbs
                keyPrefix={'recommended-'}
                scale="vga"
                onClick={handleThumbClick}
                items={recommendedProducts}
                texts={recommendedProducts.map(getThumbText)}
              />
            </div>
          </div>
        )}
      </div>
      {/* {previewImage && <MobileAppAd appName={previewImage?.name} backImageUrl={previewImage?.preview_image?.url} />} */}
    </>
  );
});
