export const productSchema = `
  @type,
  id, title, display_title,
  sequence_number, series_count, seasons_count, episodes_count, preview_asset_id,
  inherited_preview_asset_id, inherited_preview_image, parent {type, sequence_number, display_title, parent { display_title } },
  preview_asset { elements},
  default_layer {
    id, categories, category_ids, duration, duration_text, year_of_production, synopsis, copyright,
    subtitle, original_release_date_formatted, original_broadcaster, logline, highlights, 
    casts { name },
    crews { name, role },
    product_type, rating,
  }
`;

export const marketingAssetSchema = `
  @type,
  id, effective_permissions, classification, name,
  duration, protection_levels, preview_image_id,
  preview_image { url, distribution_url, signing_key },
  default_layer { id, description, notes }
`;

export const productVideosAssetSchema = `
  id, name, classification, duration,
  products { product_id, display_title },
  languages { name },
  preview_image
`;
