import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { chipmunk, start } from 'utils';
import { useParams } from 'react-router-dom';
import { Icon, Loading, ProductList, SavedListItem, TopSection } from 'components';
import { autorun } from 'mobx';
import { Actions, Modal, OFFCANVAS } from 'types';
import { Routes } from 'routes';
import useSWRInfinite from 'swr/infinite';
import './styles.scss';
import { toggleOffcanvas } from 'store/offcanvas-store';
import { CategoryFilters } from 'pages/category/category-filters';
import { useBaskets } from 'utils/hooks/use-baskets';
import { productListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import { useBasketsProducts } from 'utils/hooks/use-basket-products';
import { BasketsStore, useBasketsStore } from 'store/baskets-store/baskets-store';
import { useShallow } from 'zustand/react/shallow';
import { fetchProductdIds } from 'utils/api/fetch-product-ids';
import { applyFilters } from 'store/apply-filters';
import { useInViewport } from '@mantine/hooks';

export const SavedList: React.FC = observer(() => {
  const [startIsDone, setStartIsDone] = useState(false);
  const store = useStore();
  const { ref, inViewport } = useInViewport();

  useEffect(() => {
    autorun(async () => {
      await start(store);
      setStartIsDone(true);
    });
  }, [store]);

  const { modalStore, toastStore, routing, sessionStore } = store;

  const { id } = useParams<{ id: string }>();
  const isRootRoute = !id;
  const isAllFavorites = id === 'favorites';

  const basket = useBaskets(sessionStore?.session?.user?.id);
  const { basketData: baskets, isBasketsLoading, mutateBaskets } = basket;
  const basketProductsData = useBasketsProducts(baskets);
  const { basketProducts, isBasketProductsLoading } = basketProductsData;
  const isLoadingBaskets = isBasketsLoading || isBasketProductsLoading;

  const { filters, page, per, sort, order } = useBasketsStore(
    useShallow(
      ({ filters, page, per, sort, order }): Partial<BasketsStore> => ({
        filters,
        per,
        sort,
        order,
        page,
      }),
    ),
  );

  const swr = useSWRInfinite(
    (pageIndex) =>
      startIsDone && !isRootRoute
        ? ['saved-list-load-products', pageIndex + 1, per, sort, order, filters, id, baskets]
        : null,
    async (params) => {
      const [, page, per, sort, order, filters, id, baskets] = params;
      const productIds = await fetchProductdIds(id, baskets);

      const updatedFilters = Object.values(filters)
        .filter((item) => item.payload)
        .map((item) => item.payload);

      // permanent filters
      updatedFilters.push(['id', 'in', productIds]);

      return chipmunk.run(
        () =>
          chipmunk.action('pm.product', 'search', {
            body: {
              sort,
              order,
              page,
              per,
              only_roots: 'false',
              search: {
                filters: updatedFilters,
              },
            },
            schema: productListSchema.replace('product_state,', 'product_state,product_type,'),
          }),
        (e: Error) => console.log(e),
      );
    },
    { keepPreviousData: true, parallel: true },
  );

  const { data, isLoading, setSize, size } = swr;
  const lastPageData = data?.length ? data[data.length - 1] : {};
  const { pagination } = lastPageData;
  const { total_count = 0, total_pages } = pagination ?? {};
  const products = data?.flatMap((page) => page.objects) ?? [];

  const initialLoading = !startIsDone || (typeof data === 'undefined' && isLoading);
  const isLoadingMore = !initialLoading && (isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'));
  const noResults = !initialLoading && products?.length === 0;

  useEffect(() => {
    if (initialLoading || noResults || !inViewport || isLoadingMore) {
      return;
    }

    // page increment will call setSize
    if (size < total_pages) {
      useBasketsStore.setState((state) => ({
        page: state.page + 1,
      }));
    }
  }, [inViewport, isLoadingMore, size, total_pages, initialLoading, noResults]);

  // control size from the store
  useEffect(() => {
    if (page !== size) {
      setSize(page);
    }
  }, [page, setSize, size]);

  const handleCreate = (): void => {
    modalStore.open(Modal.EDIT_LIST, {
      props: {
        mutateBaskets,
      },
    });
  };

  const handleEdit = async (basket): Promise<void> => {
    modalStore.open(Modal.EDIT_LIST, {
      props: {
        basket,
        onSave: mutateBaskets,
      },
    });
  };

  const handleDeleteBasket = async (basketId, redirect = false): Promise<void> => {
    modalStore.open(Modal.CONFIRMATION_MODAL, {
      sizeClass: 'modal-md',
      props: {
        bodyText: 'Do you want to delete this list?',
        confirmText: 'Delete',
        confirmAction: () => {
          return chipmunk.run(
            async ({ action }) => {
              await action('pm.basket', 'member.delete', {
                params: { basket_id: basketId },
              });

              mutateBaskets();
              toastStore.success('List deleted');
              if (redirect) routing.push(`${Routes.SAVED_LISTS}`);
              return true;
            },
            () => {
              toastStore.error('List cannot be deleted');
              return false;
            },
          );
        },
      },
    });
  };

  const hasBaskets = Boolean(baskets.length);

  const handleClearClick = async (): Promise<void> => {
    applyFilters({ action: Actions.RESET, store: useBasketsStore });
  };

  let allBasketProducts = [];

  Object.values(basketProducts).map((basket) => {
    allBasketProducts = [...allBasketProducts, ...basket];
  });

  const currentBasket = baskets?.find((basket) => basket.id == id);
  const name = isAllFavorites ? 'All Favorites' : currentBasket?.name;

  const handleProductDelete = async (e): Promise<void> => {
    const productId = e.currentTarget.dataset.id;
    modalStore.open(Modal.CONFIRMATION_MODAL, {
      sizeClass: 'modal-md',
      props: {
        bodyText: 'Do you want to delete this item?',
        confirmText: 'Delete',
        confirmAction: () => {
          return chipmunk.run(
            async ({ action }) => {
              await action('pm.basket', 'remove_products', {
                params: { basket_id: Number(id), product_ids: [Number(productId)] },
              });
              mutateBaskets();
              toastStore.success('Item removed from list');
              return true;
            },
            () => {
              toastStore.error('Item cannot be deleted');
              return false;
            },
          );
        },
      },
    });
  };

  if (isRootRoute) {
    return (
      <div className="saved-list">
        <TopSection
          store={'baskets'}
          title="Your lists"
          isSmall={true}
          customActions={
            <div className="d-flex align-items-start align-items-lg-end justify-content-end small flex-column">
              <div className="custom-link" role="button" onClick={handleCreate}>
                CREATE LIST +
              </div>
            </div>
          }
        />
        {isLoadingBaskets && <Loading />}

        {!isLoadingBaskets && (
          <div className="container-fluid--custom">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
              <div className="col mb-4">
                <SavedListItem id="favorites" title="All favorites" products={allBasketProducts} hasActions={false} />
              </div>

              {baskets?.map((basket) => {
                const products = basketProducts[basket.id];

                return (
                  <div className="col mb-4" key={basket.id}>
                    <SavedListItem
                      id={basket.id}
                      title={basket.name}
                      products={products}
                      onEdit={() => handleEdit(basket)}
                      onDelete={() => handleDeleteBasket(basket.id)}
                    />{' '}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!isLoadingBaskets && !hasBaskets && (
          <section className="py-10 text-center">
            <h5>No saved lists found</h5>
          </section>
        )}
      </div>
    );
  }

  return (
    <div className="saved-list-details">
      <TopSection
        isSmall={true}
        rootBreadcrumb={['Saved Lists', Routes.SAVED_LISTS]}
        breadcrumbs={[{ name }]}
        title={name}
        store={'baskets'}
        onFiltersClick={() =>
          toggleOffcanvas(OFFCANVAS.SAVED_LIST_FILTERS, <CategoryFilters key={OFFCANVAS.SAVED_LIST_FILTERS} />)
        }
        onClearClick={handleClearClick}
        customActions={
          !isAllFavorites ? (
            <div className="d-flex align-items-start align-items-lg-end justify-content-end small flex-column">
              <div className="saved-list-details__actions mb-5">
                <div
                  className="saved-list-details__btn p-3"
                  title="Edit"
                  onClick={() => handleEdit(baskets.find((x) => x.id == id))}
                >
                  <Icon name="pencil-square" />
                </div>
                <div
                  className="saved-list-details__btn p-3"
                  title="Delete"
                  onClick={() => handleDeleteBasket(id, true)}
                >
                  <Icon name="x-lg" />
                </div>
              </div>
            </div>
          ) : null
        }
      />

      {initialLoading && <Loading />}

      {noResults && (
        <section className="py-10 text-center">
          <h5>No titles found</h5>
        </section>
      )}

      {!initialLoading && !noResults && (
        <>
          <ProductList products={products} total={total_count} onDelete={isAllFavorites ? null : handleProductDelete} />
          {isLoadingMore && (
            <div
              className="fixed-bottom p-3 rounded m-auto d-flex align-items-center bg-primary--main mb-10 justify-content-center"
              style={{ maxWidth: 170 }}
            >
              <div className="spinner-border text-primary me-3" role="status" />
              <h5 className="m-0">Loading ...</h5>
            </div>
          )}
        </>
      )}

      <div ref={ref} className="category__placeholder" />
    </div>
  );
});
