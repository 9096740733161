import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { start } from 'utils';
import { useStore } from 'store';
import { Loading, ProductShow } from 'components';
import { recommendationParamsCheck, recommendationValidityCheck } from 'utils/recommendations';
import { Routes } from 'routes';
import { VerifyCode } from 'pages/verify-code/verify-code';

const urlParams = new URLSearchParams(window.location.search);
const eid = urlParams.get('eid');
const sid = urlParams.get('sid');
const code = urlParams.get('code');

const RecommendationsProduct: React.FC<{}> = observer(() => {
  const store = useStore();
  const { productId, recommendationId } = useParams<{ recommendationId: string; productId: string }>();
  const recoId = parseInt(eid || recommendationId, 10);

  const { recommendationsStore } = store;
  const { recommendation } = recommendationsStore;
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code && !hasAccess) return;

    (async () => {
      const { recommendationsStore, productShowStore, toastStore, routing } = store;
      const { location } = routing;
      const entityId = Number(productId);

      await recommendationParamsCheck(location);
      await start(store);

      let recommendation;

      try {
        recommendation = await recommendationsStore.getRecommendation({
          recommendationId: recoId,
          entityId,
          entityType: 'product',
          force: true,
        });
      } catch (e) {
        if (e && e.status === 404)
          sid
            ? toastStore.error('You are not able to view this recommendation because you are not the recipient.')
            : toastStore.error('You have to login to view this recommendation');
        else toastStore.error(e.object?.message || 'Something went wrong!');

        routing.push(Routes.HOME);
      }

      recommendationValidityCheck(recommendation);

      setLoading(true);

      await productShowStore.init(entityId);
      const isSeries = await productShowStore.getProduct(entityId);
      await productShowStore.getMarketingAssets(entityId);

      if (!isSeries) {
        await productShowStore.loadScreeners();
        await productShowStore.loadTrailers();
      } else {
        await productShowStore.getTree();
        await productShowStore.loadAllVideos();
      }

      await productShowStore.getSimilarProducts();

      await recommendationsStore.init();

      setLoading(false);
    })();
  }, [store, productId, recoId, hasAccess]);

  if (!hasAccess && code) {
    return <VerifyCode code={code} accessState={[hasAccess, setHasAccess]} />;
  }

  if (loading) {
    return (
      <section className="container pt-10">
        <div className="row">
          <Loading />
        </div>
      </section>
    );
  }

  return <>{recommendation && <ProductShow recommendationProductId={Number(productId)} />}</>;
});

export default RecommendationsProduct;
