import React, { useEffect, useState, useRef } from 'react';
import { Loading, ProductList } from 'components';
import { start } from 'utils';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { IVideoplayerApi, VideoPlayer } from '@mediafellows/videoplayer';

export const EventPage: React.FC<{}> = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const player = useRef<HTMLDivElement & IVideoplayerApi>(null);
  const store = useStore();
  const {
    homeStore: { specialEventsProducts },
    groupsStore: { groupAssets },
  } = store;
  const { name } = useParams<{ name }>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await start(store);
      await store.homeStore.loadSpecialEvents();
      setIsLoading(false);
    })();
  }, [store]);

  const group = specialEventsProducts.find((group) => group.name.includes(name));

  useEffect(() => {
    (async () => {
      await start(store);
      if (group) {
        await store.groupsStore.getGroupAssets(group.id);
      }
    })();
  }, [group?.id, store, group]);

  const previewAsset = groupAssets && groupAssets.find((asset) => asset);
  const isVideo = previewAsset?.classification.includes('video');

  if (!group) {
    return null;
  }

  return (
    <>
      <div className="px-4 pt-11 pt-lg-13 text-center">
        <h3 className="">{group.name}</h3>
      </div>
      <div className="container-fluid--custom">
        <div className="row mb-5">
          <div className="row">
            <div className="col-lg-6 col-12">{group?.description}</div>
          </div>
          <div className="col-md-6 col-12 mx-auto d-block pt-3">
            {isVideo && (
              <VideoPlayer
                ref={player}
                video={previewAsset}
                autoplay={false}
                posterUrl={previewAsset?.preview_image?.url}
              />
            )}

            {!isVideo && <img src={previewAsset?.preview_image?.url} className="img-fluid" />}
          </div>
        </div>
        <div className="row">
          {isLoading && (
            <div className="col-12">
              <Loading />
            </div>
          )}
          {!isLoading && (
            <div className="col-12">
              <ProductList
                showProgress={true}
                products={group.products}
                total={group.products.length}
                containerClass={''}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
});
