import { DefaultFilters, Order, Sort } from 'types';
import { defaultRuntimeEnd, defaultRuntimeStart, defaultYearEnd, defaultYearStart } from 'utils/general';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const defaultFilters: DefaultFilters = {
  category_ids: {
    value: [],
    payload: null,
  },
  year_of_production: {
    value: [defaultYearStart, defaultYearEnd],
    range: [defaultYearStart, defaultYearEnd],
    aggregationsRange: false,
    payload: null,
  },
  duration: {
    value: [defaultRuntimeStart, defaultRuntimeEnd],
    range: [defaultYearStart, defaultYearEnd],
    aggregationsRange: false,
    payload: null,
  },
};

export type CategoriesStore = {
  activeFilters: string[];
  defaultFilters: DefaultFilters;
  filters: DefaultFilters;
  order: Order;
  page: number;
  per: number;
  sort: Sort;
};

export const useCategoriesStore = create<CategoriesStore>()(
  devtools(() => ({
    activeFilters: [],
    defaultFilters,
    filters: defaultFilters,
    order: Order.DESCENDING,
    page: 1,
    per: 24,
    sort: Sort.LATEST_RELEASE_DATE,
  })),
);
