import { RootStore } from 'store/root-store';

export const start = async (rootStore: RootStore) => {
  const { sessionStore, basicStore } = rootStore;
  const session = await sessionStore.loadSession();

  if (session.mfa_check_required) {
    return;
  }

  await basicStore.loadBasics();

  if (!session || session?.isPublic) {
    return;
  }

  await sessionStore.loadUser();
};
