import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { chipmunk, start, uniqBy } from 'utils';
import { Link, useParams } from 'react-router-dom';
import { ButtonSpinner, HorizontalThumbs, Loading, ProductList, TopSection } from 'components';
import { autorun } from 'mobx';
import { Actions, CATEGORIES, FetchActions, FILM_TYPES, MAIN_CATEGORY, OFFCANVAS, TV_TYPES } from 'types';
import cx from 'classnames';
import { thumbText } from '../../components/thumbnail/thumbnail';
import { Routes } from 'routes';
import './styles.scss';
import { CategoriesStore, useCategoriesStore } from 'store/categories-store/categories-store';
import { useShallow } from 'zustand/react/shallow';
import { applyFilters } from 'store/apply-filters';
import useSWRInfinite from 'swr/infinite';
import { productListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import useSWR from 'swr';
import { fetchProductsByGroup } from 'utils/api/fetch-products-by-group';
import { toggleOffcanvas } from 'store/offcanvas-store';
import { CategoryFilters } from './category-filters';
import { fetchFeaturedData } from 'utils/api/fetch-featured-data';
import { useDidUpdate } from '@mantine/hooks';

export const Category: React.FC<{ root: string }> = observer(({ root }) => {
  const [startIsDone, setStartIsDone] = useState(false);
  const store = useStore();
  const {
    routing,
    basicStore: { categories },
  } = store;

  const { genre } = useParams<{ genre: string }>();
  const isRootCategory = !genre;
  const isMoviesRoot = root === MAIN_CATEGORY.MOVIES;
  const rootTitle = isMoviesRoot ? 'Movies' : 'Television';
  const mapping = CATEGORIES[root].find((item) => item.frontend === genre);
  const rootLink = isMoviesRoot ? MAIN_CATEGORY.MOVIES : MAIN_CATEGORY.TV;

  useEffect(() => {
    autorun(async () => {
      await start(store);
      setStartIsDone(true);
    });
  }, [store, isRootCategory, isMoviesRoot]);

  const { filters, per, sort, order, activeFilters, page } = useCategoriesStore(
    useShallow(
      ({ filters, per, sort, order, activeFilters, page }): Partial<CategoriesStore> => ({
        filters,
        per,
        sort,
        order,
        page,
        activeFilters,
      }),
    ),
  );

  const swr = useSWRInfinite(
    (pageIndex) =>
      startIsDone ? ['category-load-products', pageIndex + 1, per, sort, order, filters, genre, root] : null,
    (params) => {
      const [, size, per, sort, order, filters, genre, root] = params;
      const updatedFilters = Object.values(filters)
        .filter((item) => item.payload)
        .map((item) => item.payload);

      if (genre) {
        const category = categories.find((item) => item.name.toLowerCase() === mapping.backend);
        const categoryId = [category?.id || -1];
        updatedFilters.push(['category_ids', 'in', categoryId]);
      }

      // permanent filter
      updatedFilters.push(['product_type', 'in', root === 'tv' ? TV_TYPES : FILM_TYPES]);

      return chipmunk.run(
        () =>
          chipmunk.action('pm.product', 'search', {
            body: {
              sort,
              order,
              page: size,
              per,
              search: {
                filters: updatedFilters,
              },
            },
            schema: productListSchema.replace('product_state,', 'product_state,product_type,'),
          }),
        (e: Error) => console.log(e),
      );
    },
    { keepPreviousData: true },
  );

  const { data, isLoading, size, setSize } = swr;
  const lastPageData = data?.length ? data[data.length - 1] : {};
  const { pagination } = lastPageData;
  const { total_count = 0, total_pages } = pagination ?? {};

  const products = data?.flatMap((page) => page.objects) ?? [];
  const initialLoading = !startIsDone || (typeof data === 'undefined' && isLoading);
  const isLoadingMore = !initialLoading && (isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'));
  const noResults = !initialLoading && products?.length === 0;
  const shouldRenderLoadMoreButton = total_pages > 1;

  // reset page on route change
  useDidUpdate(() => {
    useCategoriesStore.setState({
      page: 1,
    });
  }, [genre]);

  // control size from the store
  useEffect(() => {
    if (page !== size) {
      setSize(page);
    }
  }, [page, setSize, size]);

  const { data: genreProductsByGroups, isLoading: isLoadingProductsByGroups } = useSWR(
    startIsDone ? FetchActions.PRODUCTS_BY_GROUP : null,
    fetchProductsByGroup,
  );

  const shouldFetchFeaturedTV = isRootCategory && !isMoviesRoot;
  const shouldFetchFeaturedMovies = isRootCategory && isMoviesRoot;

  const { isLoading: isLoadingFeaturedTV } = useSWR(
    startIsDone && shouldFetchFeaturedTV ? FetchActions.FEATURED_TV : null,
    () => fetchFeaturedData('tv'),
  );

  const { isLoading: isLoadingFeaturedMovies } = useSWR(
    startIsDone && shouldFetchFeaturedMovies ? FetchActions.FEATURED_MOVIES : null,
    () => fetchFeaturedData('movies'),
  );

  const isLoadingFeatured = isMoviesRoot ? isLoadingFeaturedMovies : isLoadingFeaturedTV;

  const breadcrumbs = [{ name: mapping?.frontend }];
  const filterSubgenreFeatured = genreProductsByGroups?.filter(({ group }) => group?.includes(root)) || [];

  const isLoadingRootCategory = isLoadingFeatured || isLoadingProductsByGroups;

  const handleThumbClick = useCallback(
    (_, { id }) => {
      routing.push(`${Routes.PRODUCT}/${id}`);
    },
    [routing],
  );

  const getThumbText = (product) => {
    const { default_layer, title } = product;
    const { year_of_production, rating } = default_layer || {};

    return {
      title,
      content: [year_of_production, rating],
    };
  };

  const handleClearClick = async (): Promise<void> => {
    applyFilters({ action: Actions.RESET, store: useCategoriesStore });
  };

  const handleLoadMore = () => {
    useCategoriesStore.setState((state) => ({
      page: state.page + 1,
    }));
  };

  const groupProducts =
    genreProductsByGroups?.find((item) => item.group === `${root}_${mapping?.group}_featured`)?.products || [];
  const filteredProducts = uniqBy([...groupProducts, ...products], 'id');
  const isLoadingSubgenre = isLoading || isLoadingProductsByGroups;

  if (isRootCategory) {
    return (
      <div className="category">
        <TopSection
          title={`Browse ${rootTitle}`}
          isSmall={true}
          extraTitle={
            <Link className="view-all-link" to={`${rootLink}/all`}>
              View All
            </Link>
          }
        />

        {Boolean(isLoadingRootCategory || !filterSubgenreFeatured.length) && <Loading />}

        {Boolean(!isLoadingRootCategory && filterSubgenreFeatured.length) && (
          <div className="container-fluid--custom">
            {filterSubgenreFeatured.map((item, index) => {
              const { group, products } = item;
              const isLast = filterSubgenreFeatured.length - 1 === index;
              const name = group.split('_')?.[1];

              const thumbTexts: thumbText[] = products.map(getThumbText);

              return (
                <div className={cx('row', { 'mb-4': !isLast })} key={`${group}-${index}`}>
                  <div className="d-flex align-items-baseline">
                    <h3 className="text-capitalize mb-1">{name}</h3>
                    <Link className="view-all-link mx-3" to={`${rootLink}/${name}`}>
                      View All
                    </Link>
                  </div>
                  <div className="col-12">
                    <HorizontalThumbs scale="vga" onClick={handleThumbClick} items={products} texts={thumbTexts} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="category">
      <TopSection
        title={`Browse ${genre}`}
        breadcrumbs={breadcrumbs}
        onFiltersClick={() =>
          toggleOffcanvas(OFFCANVAS.GENRE_FILTERS, <CategoryFilters key={OFFCANVAS.GENRE_FILTERS} />)
        }
        onClearClick={handleClearClick}
        isSmall={true}
        store={'category'}
      />
      {isLoadingSubgenre && <Loading />}
      {!isLoadingSubgenre && noResults && (
        <section className="py-10 text-center">
          <h5>No titles found</h5>
        </section>
      )}
      {!isLoadingSubgenre && !noResults && (
        <>
          <ProductList products={!!activeFilters.length ? products : filteredProducts} total={total_count} />
          {shouldRenderLoadMoreButton && (
            <div className="category-loadMoreButton">
              <button className="btn btn-lg btn-outline-primary" disabled={isLoadingMore} onClick={handleLoadMore}>
                <ButtonSpinner title="Load More" isLoading={isLoadingMore} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
});
