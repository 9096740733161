import { connect as pigeonConnect, enableUserNotifications, addListener } from '@mediafellows/pigeon2-client';

import { getRootStore } from 'store';
import { chipmunk } from 'utils/chipmunk';

export const connect = async (userId: null | number): Promise<void> => {
  await pigeonConnect(chipmunk);
  if (userId) enableUserNotifications();

  addListener('message', ({ message, type }) => {
    const { toastStore } = getRootStore();

    switch (type) {
      case 'success':
        toastStore.success(message);
        break;

      case 'warning':
        toastStore.error(message);
        break;

      case 'error':
        toastStore.error(message);
        break;
    }
  });
};

export default connect;
