import { action, IObservableArray, observable } from 'mobx';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export class ToastStore {
  @observable public toasts = [] as IObservableArray;

  @action.bound
  public success(message: string): void {
    this.add(message, 'success');
  }

  @action.bound
  public error(message: string): void {
    this.add(message, 'danger');
  }

  @action.bound
  public updateToasts(toasts) {
    this.toasts.replace(toasts);
  }

  @action.bound
  public add(content, type) {
    const id = uuidv4();

    const toast = {
      content,
      type,
      id,
      ref: React.createRef(),
      options: { delay: 5000 },
      bootstrapToast: null,
    };

    this.updateToasts([...this.toasts, toast]);
  }
}
