import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Routes } from 'routes';
import { rememberLocation } from 'utils/path';

const PrivateRoute = observer(({ children, ...rest }) => {
  const { sessionStore, basicStore } = useStore();
  const session = sessionStore.session;
  const is2FARedirect = sessionStore.is2FArequired;

  useEffect(() => {
    sessionStore.loadSession();
  }, [sessionStore, basicStore]);

  if (!sessionStore.initialLoadDone) {
    return null;
  }

  const handleRender = ({ location }) => {
    if (is2FARedirect) {
      rememberLocation(location);
      return <Redirect to={Routes.TWO_FACTOR_AUTH} />;
    }

    if (!session || !session.isAuthenticated) {
      rememberLocation(location);
      return <Redirect to={Routes.SIGN_IN} />;
    }

    return children;
  };

  return <Route {...rest} render={handleRender} />;
});

export default PrivateRoute;
