import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { chipmunk, displayError, cookie } from 'utils';
import { ButtonSpinner, FormBox } from 'components';

export const ResetPassword: React.FC = observer(() => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { token } = useParams<{ token: string }>();
  const { toastStore } = useStore();
  const password = useRef({});
  const [isLoading, setIsLoading] = useState(false);
  password.current = watch('password', '');

  const submitCallback = ({ password, password_confirmation }): void => {
    chipmunk.run(
      async (chipmunk) => {
        setIsLoading(true);

        const payload = await chipmunk.action('um.password', 'update', {
          body: { password, password_confirmation },
          params: { password_reset_token: token },
        });

        setIsLoading(false);

        toastStore.success('Your password has been changed');

        // password reset returns a new session!
        cookie.set('sessionId', payload?.object?.id);
        setTimeout(() => (window.location.href = '/'), 2000);
      },
      (err) => {
        const msg = err?.object?.description || err.mesage;
        if (msg) {
          toastStore.error(msg);
        } else {
          toastStore.error('Failed to update password. Please try again.');
        }
        setIsLoading(false);
      },
    );
  };

  return (
    <FormBox colClass="col-12 col-md-6 col-xl-5 col-xxl-4 mt-13">
      <h3>Change password</h3>
      <p className="mt-4">Password should be between 8-12 characters and contain at least one number and symbol.</p>
      <form className="row g-3 mt-8" noValidate={true} onSubmit={handleSubmit(submitCallback)}>
        <div className="col-12 mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            id="password"
            placeholder={'New password'}
            type="password"
            {...register('password', {
              required: true,
            })}
            autoComplete="off"
            className={cx('form-control', { 'is-invalid': errors?.password })}
          />
          {displayError(errors?.password, 'Password')}
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
          <input
            id="confirmPassword"
            placeholder={'Confirm new password'}
            type="password"
            {...register('password_confirmation', {
              required: true,
              validate: (value) =>
                value === password.current || 'Passwords do not match. Please retype them and try again.',
            })}
            autoComplete="off"
            className={cx('form-control', { 'is-invalid': errors?.password_confirmation })}
          />
          {displayError(errors?.password_confirmation, 'Password confirmation')}
        </div>
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center flex-column">
            <div>
              <button type="submit" disabled={isLoading} className="btn btn-lg btn-outline-primary">
                <ButtonSpinner title="Reset Password" isLoading={isLoading} />
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormBox>
  );
});
