import { Icon, Loading } from 'components';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { Routes } from 'routes';
import cx from 'classnames';
import { sendPagePing } from '../../utils/page-pings';

export const AccountContent = observer(() => {
  const { sessionStore, basicStore } = useStore();
  const { logout, user } = sessionStore;
  const { salesContacts, isLoadingSalesContacts } = basicStore;
  const { responsible_user_id } = user || {};

  useEffect(() => {
    (async () => {
      await basicStore.loadSalesContacts();
    })();
  }, [basicStore]);

  const handleSignOut = async (): Promise<void> => {
    await logout();
    await sendPagePing('logged-out');
  };

  const salesContact = salesContacts.find((user) => user.id === responsible_user_id);
  const { first_name, last_name, email, phones = [] } = salesContact || {};
  const phone = phones?.[0]?.number;

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="d-inline-block pointer text-primary" data-bs-dismiss="offcanvas">
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-2">
        <div className="col">
          <h3 className="mb-5">My Account</h3>
          <hr className="d-none d-sm-block" />
          <div className="row row-cols-1 row-cols-lg-2 mt-5">
            <div className="col mb-6">
              <Link to={Routes.ACCOUNT_SETTINGS} className="h4 header__link-offcanvas">
                Settings
              </Link>
            </div>
            <div className="col mb-6">
              <Link to={Routes.SAVED_LISTS} className="h4 header__link-offcanvas">
                Saved Lists
              </Link>
            </div>
            <div className="col mb-6">
              <Link to={Routes.RECOMMENDATIONS_LIST} className="h4 header__link-offcanvas">
                Recommended
              </Link>
            </div>
            <div className="col mb-6">
              <Link to={Routes.CONTINUE_WATCHING} className="h4 header__link-offcanvas">
                Continue Watching
              </Link>
            </div>
            <div className="col-12">
              <button className="btn btn-lg btn-outline-primary mb-8" onClick={handleSignOut}>
                Sign Out
              </button>
            </div>
          </div>
        </div>
        {isLoadingSalesContacts && (
          <div className="col">
            <Loading />
          </div>
        )}
        {!isLoadingSalesContacts && (
          <div className="col">
            <div className="d-flex align-items-center justify-content-between mb-5">
              {salesContact && (
                <h3 className="mb-0">
                  Your contact is {first_name || '-'} {last_name}{' '}
                </h3>
              )}

              {!salesContact && <h3 className="mb-0">No sales contact found</h3>}
            </div>
            <hr className="d-none d-sm-block" />
            {salesContact && (
              <div className="row mt-5">
                <div className="col-12 mb-6">
                  <div className="h4">{email || '-'}</div>
                </div>
                <div className="col-12 mb-6">
                  <div className="h4">{phone || '-'}</div>
                </div>
                <div className="col-12">
                  <a href={`mailto:${email}`} className={cx('btn btn-lg btn-outline-primary', { disabled: !email })}>
                    Connect
                  </a>
                </div>
              </div>
            )}
            {!salesContact && (
              <div className="row mt-5">
                <div className="col-12">
                  <a href={`mailto:lgtv@lionsgate.com`} className={cx('btn btn-lg btn-outline-primary')}>
                    Connect
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
});
