import { useRef } from 'react';
import { csv } from 'utils';
import { filter, find } from 'lodash';
import React from 'react';
import { Routes } from 'routes';
import { Link } from 'react-router-dom';
import { CATEGORIES } from '../../types';
import { useSize } from 'utils/hooks/use-size';
import cx from 'classnames';

const doCleanup = (html: string): string => {
  const foo = document.createElement('div');
  foo.innerHTML = html;

  foo.querySelectorAll('*').forEach((el) => {
    el.removeAttribute('class');
    el.removeAttribute('style');
  });
  return foo.innerHTML;
};

const onScroll = (event: React.UIEvent) => {
  const target = event.target;
  if (target instanceof HTMLDivElement) {
    const targetHeight = target.getBoundingClientRect().height;
    const maxScrollTop = target.scrollHeight - targetHeight;
    const scrollPercent = Math.max(Math.round((target.scrollTop / maxScrollTop) * 100), 60);
    target.style.setProperty('--overlayHeight', `${scrollPercent}%`);
  }
};

const ProductMetadata = ({ data, className, year_of_production, productType }) => {
  const { default_layer } = data;
  const { categories, original_broadcaster, produced_by, casts, crews, highlights, synopsis } = default_layer;
  const heightRef = useRef(null);

  const height = useSize(heightRef) as { height?: number };
  const hasOverflow = heightRef.current?.scrollHeight > Math.ceil(height?.height);

  const isSeries = data['@type'].includes('series');
  const isSeason = data['@type'].includes('season');
  const isEpisode = data['@type'].includes('episode');

  const hideProducer = isSeries || isSeason || isEpisode;

  const tvCategoryNames = CATEGORIES.tv.map((c) => c.frontend);
  const movieCategoryNames = CATEGORIES.movies.map((c) => c.frontend);
  const thisCategoryNames = productType === 'television' ? tvCategoryNames : movieCategoryNames;

  return (
    <div className={cx('product-show__metadata', className)}>
      <div
        className="product-show__metadata-overflow"
        onScroll={onScroll}
        ref={heightRef}
        style={
          hasOverflow
            ? ({
                '--overlayHeight': '60%',
              } as React.CSSProperties)
            : {}
        }
      >
        {Boolean(categories && categories.length) && (
          <div className="d-none">
            <div className="h6">Category</div>
            <div className="mb-3">
              {categories.map(({ name }, index) => {
                const isLast = index === categories.length - 1;
                const isRoot = thisCategoryNames.includes(name.toLowerCase());

                return (
                  <React.Fragment key={index}>
                    {isRoot && (
                      <Link
                        to={[productType === 'television' ? Routes.TV : Routes.MOVIES, name.toLowerCase()].join('/')}
                      >
                        {name}
                      </Link>
                    )}
                    {!isRoot && <span>{name}</span>}

                    {!isLast && <span>,&nbsp;</span>}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}

        {synopsis && (
          <>
            <div className="h6">Synopsis</div>
            <div className="mb-3" dangerouslySetInnerHTML={{ __html: doCleanup(synopsis) }} />
          </>
        )}

        {casts && (
          <>
            <div className="h6">Cast</div>
            <div className="mb-3">{csv(casts, 'name')}</div>
          </>
        )}

        {highlights && (
          <>
            <div className="h6">Highlights</div>
            <div className="mb-3" dangerouslySetInnerHTML={{ __html: highlights }} />
          </>
        )}

        {original_broadcaster && (
          <>
            <div className="h6">Original Broadcaster</div>
            <div className="mb-3">{original_broadcaster}</div>
          </>
        )}

        {produced_by && (
          <>
            <div className="h6">Produced by</div>
            <div className="mb-3">{produced_by}</div>
          </>
        )}

        {year_of_production && (
          <div className="d-none">
            <div className="h6">Year of Production</div>
            <div className="mb-3">{year_of_production}</div>
          </div>
        )}

        {find(crews, { role: 'Director' }) && (
          <div className="d-none">
            <div className="h6">Director</div>
            <div className="mb-3">{csv(filter(crews, { role: 'Director' }), 'name')}</div>
          </div>
        )}

        {find(crews, { role: 'Writer' }) && (
          <>
            <div className="h6">Writer</div>
            <div className="mb-3">{csv(filter(crews, { role: 'Writer' }), 'name')}</div>
          </>
        )}

        {!hideProducer && find(crews, { role: 'Producer' }) && (
          <>
            <div className="h6">Producer</div>
            <div className="mb-3">{csv(filter(crews, { role: 'Producer' }), 'name')}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductMetadata;
