import React, { useEffect, useState, useRef } from 'react';
import { Loading, ProductList } from 'components';
import { start } from 'utils';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { IVideoplayerApi, VideoPlayer } from '@mediafellows/videoplayer';

interface ICollectionPage {
  groupId?: number;
}

export const RecommendationCollectionPage: React.FC<ICollectionPage> = observer(({ groupId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const player = useRef<HTMLDivElement & IVideoplayerApi>(null);
  const store = useStore();
  const {
    groupsStore: { groupAssets },
    recommendationsStore: { recommendation, products },
  } = store;

  useEffect(() => {
    (async () => {
      await start(store);
      setIsLoading(false);
    })();
  }, [store]);

  useEffect(() => {
    (async () => {
      if (groupId) {
        await store.groupsStore.getGroupAssets(groupId);
      }
    })();
  }, [groupId, store]);

  const previewAsset = groupAssets && groupAssets.find((asset) => asset);
  const isVideo = previewAsset?.classification.includes('video');
  const title = recommendation.campaign.message;

  if (!products) {
    return null;
  }

  return (
    <>
      <div className="px-4 pt-11 pt-lg-13 text-center">
        <h3 className="">{title}</h3>
      </div>
      <div className="container-fluid--custom">
        <div className="row mb-5">
          <div className="col-md-6 col-12 mx-auto d-block pt-3">
            {isVideo && (
              <VideoPlayer
                ref={player}
                video={previewAsset}
                autoplay={false}
                posterUrl={previewAsset?.preview_image?.url}
              />
            )}

            {!isVideo && <img src={previewAsset?.preview_image?.url} className="img-fluid" />}
          </div>
        </div>
        <div className="row">
          {isLoading && (
            <div className="col-12">
              <Loading />
            </div>
          )}
          {!isLoading && (
            <div className="col-12">
              <ProductList showProgress={true} products={products} total={products.length} containerClass={''} />
            </div>
          )}
        </div>
      </div>
    </>
  );
});
