import { action, observable, IObservableArray } from 'mobx';
import { tuco, chipmunk } from 'utils';
import { assetListSchema, productListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import { compact, map, sortBy } from 'lodash';
import { productSchema } from 'pages/product/schemas';

export class HomeStore {
  @observable public continueWatching = [] as IObservableArray;
  @observable public heroProducts = [] as IObservableArray;
  @observable public heroMainImage = null;
  @observable public appAssets = [] as IObservableArray;
  @observable public specialEventsProducts = [] as IObservableArray;
  @observable public recommendedProducts = [] as IObservableArray;

  private heroPromise: Promise<any>;
  private appPromise: Promise<any>;
  private specialEventsPromise: Promise<any>;
  private recommendedProductsPromise: Promise<any>;

  @action.bound
  public async loadContinueWatching() {
    chipmunk.run(
      async (chipmunk) => {
        const productData = await tuco('getVideoContinuations', { productSchema: productSchema });
        const { objects: products = [] } = productData || {};

        const assetData = await chipmunk.action('am.asset', 'get', {
          params: { asset_ids: products.map((a) => a.videoId) },
          schema: `id, classification, name, preview_image, duration, default_layer { description, notes }`,
        });
        const { objects: assets = [] } = assetData || {};

        this.continueWatching.replace(
          products.map((product) => {
            const asset = assets.find(({ id }) => id === product.videoId) || {};

            return {
              ...product,
              asset,
              classification: asset.classification,
              ['@type']: asset['@type'],
            };
          }),
        );
      },
      () => {
        console.log('failed to load continue watching');
      },
    );
  }

  @action.bound
  public async loadHeroProducts(reload = false) {
    if (this.heroPromise && !reload) return this.heroPromise;

    const promise = async () => {
      try {
        const data = await tuco('gcuiCarousel', {
          purpose: 'hero_slider_home',
          productSchema: productListSchema.replace('product_state,', 'product_state,product_type'),
        });

        const { objects: products = [] } = data || {};
        this.heroMainImage = products?.[0]?.preview_image?.url;
        this.heroProducts.replace(products);
      } catch (e) {
        this.heroPromise = null;
        console.log('failed to load hero products');
      }
    };

    return (this.heroPromise = promise());
  }

  @action.bound
  public async loadAppAssets(reload = false) {
    if (this.appPromise && !reload) return this.appPromise;

    const promise = async () => {
      chipmunk.run(
        async (chipmunk) => {
          const result = await chipmunk.action('um.group', 'get_purpose', {
            params: { purpose: 'lp_apps' },
            schema: `id`,
          });

          if (!result?.object?.id) {
            console.log('failed to load lp_event group');
            return;
          }

          const params = {
            group_ids: [result.object.id],
            per: 250,
            sort: 'sequence_number',
            order: 'asc',
            type: 'group/item/asset',
          };

          const items = (
            await chipmunk.action('am.group/item', 'query', {
              params,
              schema: `asset { ${assetListSchema} }`,
            })
          ).objects;

          this.appAssets.replace(compact(map(items, 'asset')));
        },
        () => {
          this.appPromise = null;
          console.log('failed to load app assets');
        },
      );
    };

    return (this.appPromise = promise());
  }

  @action.bound
  public async loadRecommendedProducts(reload = false) {
    if (this.recommendedProductsPromise && !reload) return this.recommendedProductsPromise;

    const promise = async () => {
      chipmunk.run(
        async () => {
          const data = await tuco('getPurposeGroupProducts', {
            purpose: 'lp_recommended',
            schema: productListSchema.replace('product_state,', 'product_state,product_type,'),
          });

          const { objects: products = [] } = data || {};
          this.recommendedProducts.replace(products);
        },
        () => {
          this.recommendedProductsPromise = null;
          console.log('failed to load recommended products');
        },
      );
    };

    return (this.recommendedProductsPromise = promise());
  }

  @action.bound
  public async loadSpecialEvents(reload = false) {
    if (this.specialEventsPromise && !reload) return this.specialEventsPromise;

    const promise = async () => {
      chipmunk.run(
        async () => {
          const data = await tuco('getPurposeGroups', {
            purposeRegex: '^lp_event',
            schema: 'id, name, description, preview_image, external_reference, purpose, sequence_number',
          });

          const { objects = [] } = data || {};
          const groups = sortBy(objects, 'sequence_number');

          const result = (
            await Promise.all(
              groups.map(async (group) => {
                const data = await tuco('getPurposeGroupProducts', {
                  purpose: group.purpose,
                  schema: productListSchema.replace('product_state,', 'product_state,product_type,'),
                });

                const { objects: products = [] } = data || {};

                if (!products.length) return;

                return {
                  ...group,
                  products,
                };
              }),
            )
          ).filter((x) => x);

          this.specialEventsProducts.replace(result);
        },
        () => {
          this.specialEventsPromise = null;
          console.log('failed to load special event group');
        },
      );
    };

    return (this.specialEventsPromise = promise());
  }
  @action.bound
  public resetPromises() {
    this.heroPromise = null;
    this.appPromise = null;
    this.specialEventsPromise = null;
    this.recommendedProductsPromise = null;
  }
}
