import { StoreApi, UseBoundStore } from 'zustand';
import { ZSearchStore } from 'store/search-store/search-store';

export enum General {
  OFFCANVAS_ACTIVE = 'offcanvas--active',
  OFFCANVAS_HIDNIG = 'offcanvas--hiding',
  OFFCANVAS_SHOWING = 'offcanvas--showing',
}

export enum Modal {
  EDIT_LIST = 'edit-list',
  SAVE_TO_LIST = 'save-to-list',
  PREVIEW_ASSETS = 'preview-assets',
  CONFIRMATION_MODAL = 'confirmation-modal',
  SEARCH_MODAL = 'search-modal',
  CHANGE_PASSWORD = 'change-password',
}

export enum MAIN_CATEGORY {
  TV = 'tv',
  MOVIES = 'movies',
}

export enum OFFCANVAS {
  ACCOUNT = 'account',
  SIDEBAR = 'sidebar',
  GENRE_FILTERS = 'genre-filters',
  SEARCH_FILTERS = 'search-filters',
  SAVED_LIST_FILTERS = 'saved-list-filters',
}

export const CATEGORIES = {
  tv: [
    { frontend: 'drama', backend: 'drama' },
    { frontend: 'comedy', backend: 'comedy' },
    { frontend: 'unscripted', backend: 'unscripted' },
    { frontend: 'event series', backend: 'event series' },
    { frontend: 'kids', backend: 'childrens' },
    { frontend: 'formats', backend: 'formats' },
  ],
  movies: [
    { frontend: 'drama', backend: 'drama', group: 'drama' },
    { frontend: 'comedy', backend: 'comedy', group: 'comedy' },
    { frontend: 'action', backend: 'action', group: 'action' },
    { frontend: 'family', backend: 'family' },
    { frontend: 'thriller', backend: 'thriller' },
    { frontend: 'horror', backend: 'horror' },
    { frontend: 'documentary', backend: 'documentary' },
  ],
};

export const RATINGS = ['G', 'PG', 'PG-13', 'R', 'NC-17', 'NR'];

export const FILM_TYPES = ['Collection', 'Franchise', 'Movie', 'Short'];

export const TV_TYPES = [
  'Ad',
  'Episode',
  'Excerpt',
  'Movie of the Week',
  'Non-episodic Show',
  'Promotion',
  'Season',
  'Series',
  'Special',
  'Supplemental',
  'TV',
];

export const VIDEO_CLASSIFICATIONS = {
  'video/bonus': 'Bonus',
  'video/epk': 'EPK',
  'video/interview': 'Interview',
  'video/making_of': 'Making Of',
  'video/promo': 'Promo',
  'video/screener': 'Screener',
  'video/trailer': 'Trailer',
  'video/featurette': 'Featurette',
};

export const PRODUCTS_CLASSIFICATIONS = {
  'product/motion_picture/season': 'Season',
  'product/motion_picture/series': 'Series',
};

export interface Contact {
  '@type': string;
  access_level?: string;
  activated_at: string;
  mfa_auth_method: string;
}

export interface Session {
  ['@type']: string;
  id: string;
  user?: Contact;
  affiliation: { id: string };
  role: { id: number; name?: string; designation: string };
  '@associations'?: Record<string, unknown>;
  isPublic: boolean;
  isRecommendation: boolean;
  isAuthenticated: boolean;
  mfa_check_required: boolean;
  isEvent: boolean;
  isPrivate: boolean;
}

export type FilterValue = boolean | string | number;
export type FilterArray = (string | number)[];
export type ProductTypeFilterValue = 'movies' | 'tv';
export type SearchFilter =
  | [string, string, FilterValue | FilterArray]
  | [string, FilterValue | FilterArray]
  | [string, string, string | number, string | number];

export interface DefaultFilterRecord {
  value: FilterValue | FilterArray | ProductTypeFilterValue;
  range?: FilterArray;
  aggregationsRange?: boolean;
  payload: SearchFilter | null;
}
export interface DefaultFilters {
  year_of_production?: DefaultFilterRecord;
  duration?: DefaultFilterRecord;
  category_ids?: DefaultFilterRecord;
  product_type?: DefaultFilterRecord;
  rating?: DefaultFilterRecord;
  q?: DefaultFilterRecord;
  id?: DefaultFilterRecord;
}

export type FilterStore = ZSearchStore;
export type GenericSearchStore = UseBoundStore<StoreApi<FilterStore>>;

export enum Order {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum Sort {
  DISPLAY_TITLE = 'display_title',
  TIFF_TITLE_JP = 'default_layer.meta.title_jp',
  YEAR_OF_PRODUCTION = 'year_of_production',
  ORIGINAL_RELEASE_DATE = 'original_release_date',
  PRODUCTS_YEAR_OF_PRODUCTION = 'default_layer.meta.year_of_production',
  CATEGORY = 'default_layer.meta.category_ids',
  COUNTRY_OF_ORIGIN = 'default_layer.meta.country_of_origin_ids',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  NAME = 'name',
  PUBLISHED_AT = 'published_at',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  TITLE = 'title',
  FILE_SIZE = 'file_size',
  SUBJECT = 'subject',
  LAST_LOGIN = 'last_login_at',
  FLAT_SEQUENCE_NUMBER = 'flat_sequence_number',
  SEQUENCE_NUMBER = 'sequence_number',
  LATEST_RELEASE_DATE = 'latest_release_date',
}

export enum Actions {
  RESET = 'reset',
  RESET_ALL = 'reset-all',
}

export type ApplyFiltersOptions = {
  values?: [filterName: string, value: FilterValue | FilterArray];
  action?: Actions;
  store: GenericSearchStore;
};

export enum ActiveFiltersNames {
  product_type = 'category',
  category_ids = 'genres',
  year_of_production = 'year',
  duration = 'runtime',
  rating = 'rating',
}

export enum FetchActions {
  PRODUCTS_BY_GROUP = 'products-by-group',
  FEATURED_TV = 'featured-tv',
  FEATURED_MOVIES = 'featured-movies',
}

export type BasicsStoreCategories = {
  id: number;
  name: string; // TODO add more specific type.
};
