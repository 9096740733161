import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { ButtonSpinner, Icon } from 'components';
import { useStore } from 'store';
import { Modal } from 'types';
import cx from 'classnames';
import { useSearchStore } from 'store/search-store/search-store';
import { applyFilters } from 'store/apply-filters';

const SearchModal: React.FC = observer(() => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const store = useStore();
  const { modalStore } = store;
  const existingSearchValue = useSearchStore.getState().filters?.q?.value as string;
  const [searchValue, setSearchValue] = useState(existingSearchValue || '');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!searchValue) return;
    setValue('search', searchValue);
  }, [searchValue, setValue]);

  const submitCallback = async ({ search }) => {
    setIsLoading(true);
    setSearchValue(search);
    applyFilters({ values: ['q', search], store: useSearchStore });
    setIsLoading(false);

    modalStore.hide(Modal.SEARCH_MODAL);
  };

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title"></h5>
        <div className="close" data-bs-dismiss="modal" aria-label="Close">
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="modal-body px-7">
        <form id="editListForm" noValidate={true} onSubmit={handleSubmit(submitCallback)}>
          <div className="form-group">
            <label htmlFor="list-name" className="form-label">
              Search
            </label>
            <input
              type="text"
              placeholder={'Search'}
              {...register('search', {
                required: true,
              })}
              className={cx('form-control', { 'is-invalid': errors?.search })}
              id="list-name"
            />
            {errors?.search && (
              <div className="invalid-feedback">
                {errors?.search?.type === 'required' && 'Search text is required.'}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-center mb-3">
        <div className="d-flex justify-content-center flex-column text-center">
          <button type="submit" form="editListForm" disabled={isLoading} className="btn btn-lg btn-outline-primary">
            <ButtonSpinner title="Search" isLoading={isLoading} />
          </button>
        </div>
      </div>
    </>
  );
});

export default SearchModal;
