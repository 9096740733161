import { productListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import useSWR from 'swr';
import { chipmunk } from 'utils';
import { IRequestError } from '@mediafellows/chipmunk/dist/src/request';

type DataType = Record<number, Record<string, string>[]>;
export const useBasketsProducts = (
  basketData: any[],
): { basketProducts: DataType; isBasketProductsLoading: boolean } => {
  let products = {};

  const swr = useSWR(basketData.length ? ['basket-products', basketData] : null, ([, basketData]) =>
    chipmunk.run(
      async (chipmunk): Promise<Record<number, Record<string, string>[]>> => {
        const promises = [];

        basketData.map(({ id }) => {
          promises.push(
            chipmunk.action('pm.basket', 'query_products', {
              params: { basket_id: id, per: 9 },
              schema: productListSchema,
            }),
          );
        });

        const data = await Promise.all(promises);

        basketData.map(({ id }, index) => {
          products = { ...products, [id]: data[index]?.objects };
        });

        return products;
      },
      (e: IRequestError) => {
        console.log(e, 'Failed to load basket products');
      },
    ),
  );

  const { data, isLoading: isBasketProductsLoading } = swr;
  return { basketProducts: data || { 1: [] }, isBasketProductsLoading };
};
