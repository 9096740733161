import React, { useEffect, useState } from 'react';
import { deepFind, getType } from 'utils';
import { get, isEmpty, each } from 'lodash';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import { CATEGORIES } from '../../types';

const ProductBreadcrumbs = ({ product, categories }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const type = getType(product?.default_layer?.product_type);
  const route = type === 'Television' ? Routes.TV : Routes.MOVIES;

  useEffect(() => {
    const breadcrumbs = [];

    const productCategoryIds = get(product, 'default_layer.category_ids');
    const productCategory = !isEmpty(productCategoryIds) ? deepFind(categories, productCategoryIds[0]) : null;
    const parentCategory = deepFind(categories, productCategory?.parent_id);

    if (productCategoryIds) {
      each(productCategoryIds, (id) => {
        const category = deepFind(categories, id);
        if (category) breadcrumbs.push({ name: category.name, id: category.id });
      });
    }

    if (parentCategory && !breadcrumbs.some((item) => item.name === parentCategory.name)) {
      breadcrumbs.unshift({ name: parentCategory.name, id: parentCategory.id });
    }

    setBreadcrumbs(breadcrumbs);
  }, [categories, product]);

  const tvCategoryNames = CATEGORIES.tv.map((c) => c.frontend);
  const movieCategoryNames = CATEGORIES.movies.map((c) => c.frontend);

  const filteredBreadcrumbs =
    type.toLowerCase() === 'television'
      ? (breadcrumbs || []).filter((item: any) => tvCategoryNames.includes(item?.name?.toLowerCase() || ''))
      : type.toLowerCase() === 'movie'
      ? (breadcrumbs || []).filter((item: any) => movieCategoryNames.includes(item?.name?.toLowerCase() || ''))
      : [];

  const isEpisode = product['@type'].includes('episode');
  const isSeason = product['@type'].includes('season');

  return (
    <div className="product-breadcrumbs w-100 col-12 d-flex align-items-center mb-3">
      {type && (
        <>
          <Link className="text-decoration-none" to={route}>
            {type}
          </Link>
        </>
      )}

      {filteredBreadcrumbs.map((item: any, index) => {
        return (
          <React.Fragment key={index}>
            <div>&nbsp;/&nbsp;</div>
            <Link
              className="product-breadcrumb text-decoration-none"
              to={`${route}/${item.name.toLowerCase()}`}
              key={index}
            >
              {item?.name}
            </Link>
          </React.Fragment>
        );
      })}

      {isSeason && (
        <>
          <div>&nbsp;/&nbsp;</div>
          <Link className="product-breadcrumb text-decoration-none" to={`${Routes.PRODUCT}/${product.parent.id}`}>
            {product.parent.display_title}
          </Link>
        </>
      )}

      {isEpisode && (
        <>
          <div>&nbsp;/&nbsp;</div>
          <Link
            className="product-breadcrumb text-decoration-none"
            to={`${Routes.PRODUCT}/${product?.parent.parent?.id}`}
          >
            {product?.parent.parent.display_title}
          </Link>
          <div>&nbsp;/&nbsp;</div>
          <Link className="product-breadcrumb text-decoration-none" to={`${Routes.PRODUCT}/${product?.parent.id}`}>
            {product?.parent.display_title}
          </Link>
        </>
      )}
    </div>
  );
};

export default ProductBreadcrumbs;
