import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Routes } from 'routes';
import { useStore } from 'store';
import { Icon, Thumbnail } from '../index';
import { previewImage } from '@mediafellows/tuco/dist/lib/helpers';
import cx from 'classnames';
import { getType } from 'utils';
import './style.scss';

interface IProductList {
  products: any[];
  total?: number;
  containerClass?: string;
  columnsClass?: string;
  onClick?: (id: string) => void;
  onDelete?: (e: React.MouseEvent) => void;
  showProgress?: boolean;
  showType?: boolean;
  showTotalTitles?: boolean;
  root?: string;
}

const ProductList: React.FC<IProductList> = observer((props) => {
  const {
    products,
    total,
    containerClass = 'container-fluid--custom',
    onClick,
    showProgress,
    onDelete,
    columnsClass = 'col-6 col-md-3 col-xl-2',
    showType = false,
    showTotalTitles = true,
    root,
  } = props;
  const { routing } = useStore();

  useEffect(() => {
    handleScrollPosition();
  });

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem('scrollPosition');
    }
  };

  const handleClick = (e) => {
    const id = e.currentTarget.id;

    if (onClick) {
      onClick(id);
      return;
    }

    sessionStorage.setItem('scrollPosition', window.pageYOffset as any);
    routing.push(`${Routes.PRODUCT}/${id}`);
  };

  return (
    <div className={cx('product-list', containerClass)}>
      {showTotalTitles && (
        <div className="row">
          <div className="col-12 mb-3">{total} Titles</div>
        </div>
      )}
      <div className="row">
        {products.map((product) => {
          const { id, default_layer, title } = product;
          const { year_of_production, rating, product_type } = default_layer || {};

          const type = getType(product_type);

          const content = [year_of_production, rating, root];

          if (type && showType) {
            content.push(type);
          }

          return (
            <div className={`${columnsClass} mb-3 mb-xl-5 product-list__item`} key={id}>
              <div className="position-relative">
                <div id={id} onClick={handleClick}>
                  <Thumbnail
                    assetType={product['@type']}
                    image={previewImage(product)?.url}
                    showProgress={showProgress}
                    progress={product?.progress}
                    text={{
                      title,
                      content,
                    }}
                    showOverlay={false}
                  />
                </div>

                {onDelete && (
                  <div className="product-list__item-icon p-2" title="Remove" data-id={id} onClick={onDelete}>
                    <Icon name="x-lg" width={18} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default ProductList;
