import { get, toInteger } from 'lodash';
import { storageFetch, storagePut, storageDrop } from '@mediafellows/chipmunk';

import { cookie } from './cookie';
import { queryStringParams, rememberLocation } from './path';
import { getRootStore } from 'store';
import { chipmunk } from 'utils/chipmunk';
import { Routes } from 'routes';

export class RecommendationCheckError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RecommendationCheckError.prototype);
  }
}

export const rememberCampaign = (campaignId) => {
  if (campaignId) {
    storagePut('campaignId', campaignId, 60);
  }
};

export const setCampaign = async () => {
  const { sessionStore } = getRootStore();
  const campaignId = toInteger(storageFetch('campaignId'));
  const sessionCampaignId = get(sessionStore, 'session.campaign_id');

  if (campaignId && campaignId !== sessionCampaignId) {
    await chipmunk.run(
      async (chipmunk) => {
        await chipmunk.action('um.session', 'assign_campaign', {
          body: { campaign_id: campaignId },
        });
        await sessionStore.loadSession(true);
        storageDrop('campaignId');
      },
      () => {
        console.warn('failed to set campaign id');
      },
    );
  }
};

export const loadRecommendationSession = async (sessionId) => {
  const { sessionStore, toastStore, routing } = getRootStore();

  if (sessionId) {
    cookie.set('sessionId', sessionId);
    chipmunk.updateConfig({ headers: { 'Session-Id': sessionId } });
    const session = await sessionStore.loadSession(true);

    if (session?.isRecommendation) {
      // if session id was given and we have a recommendation session => all good
      return;
    }

    toastStore.error('Sorry, this link is no longer valid');
    routing.push(Routes.HOME);
    throw new RecommendationCheckError('failed to fetch recommendation session');
  }
};

// to access a recommendation user must be logged in or have a recommendation session
export const checkSession = async () => {
  const { sessionStore, toastStore, routing } = getRootStore();
  const session = await sessionStore.loadSession();

  if (!session || (!session.isAuthenticated && !session.isRecommendation)) {
    toastStore.error('You have to login to view this recommendation');
    routing.push(Routes.HOME);
    throw new RecommendationCheckError('not a valid session');
  }

  return true;
};

export interface ILocation {
  pathname: string;
  search: string;
  [x: string]: any;
}

export const recommendationParamsCheck = async (location: ILocation) => {
  const queryParams = queryStringParams(location);
  const sessionId = queryParams.sid;
  const campaignId = queryParams.cid;
  const store = getRootStore();

  rememberLocation(location);
  if (campaignId) rememberCampaign(campaignId);
  if (sessionId) await loadRecommendationSession(sessionId);

  await checkSession();

  store.recommendationsStore.is2FARecoRedirect = true;
  await setCampaign();
};

export const recommendationValidityCheck = (recommendation: any) => {
  const {
    toastStore,
    routing,
    sessionStore: { session },
  } = getRootStore();

  if (!recommendation) {
    toastStore.error(
      'This Recommendation is not valid for your user account. Please request a personal link to view this content.',
    );
    routing.push(Routes.HOME);
    throw new RecommendationCheckError('recommendation not available');
  }

  if (recommendation.views_left === -1 || new Date(recommendation && recommendation.expires_at) < new Date()) {
    toastStore.error("We're sorry, this link is no longer valid. Please ask for a new recommendation.");
    routing.push(Routes.HOME);
    throw new RecommendationCheckError('recommendation no longer valid');
  }

  const noLoginRequired = !get(recommendation, 'campaign.require_login');
  if (!session.isAuthenticated && !noLoginRequired) {
    toastStore.error('You have to login to view this recommendation');
    routing.push(Routes.HOME);
    throw new RecommendationCheckError('recommendation requires login');
  }
};
