import { Icon, Ranger } from 'components';
import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { CATEGORIES, FilterArray, MAIN_CATEGORY, OFFCANVAS, ProductTypeFilterValue } from 'types';
import cx from 'classnames';
import { defaultRuntimeEnd, defaultRuntimeStart, defaultYearEnd, defaultYearStart, uniqBy } from 'utils';
import { useStore } from 'store';
import { applyFilters } from 'store/apply-filters';
import { useSearchStore } from 'store/search-store/search-store';
import { useCategoriesStore } from 'store/categories-store/categories-store';
import { getSelectedGenres } from 'utils/get-selected-genres';
import { useOffcanvasStore } from 'store/offcanvas-store';
import { useBasketsStore } from 'store/baskets-store/baskets-store';

const customTicks = (start, end, step = 20) => {
  const ticks = [];
  for (let i = start; i <= end; i = i + step) {
    ticks.push(i);
  }

  return ticks;
};

const getStore = (activeName) => {
  if (activeName === OFFCANVAS.GENRE_FILTERS) {
    return useCategoriesStore;
  }

  if (activeName === OFFCANVAS.SAVED_LIST_FILTERS) {
    return useBasketsStore;
  }

  return useSearchStore;
};

export const CategoryFilters = observer(() => {
  const {
    basicStore: { categories },
  } = useStore();
  const activeName = useOffcanvasStore((state) => state.activeName);
  const store = getStore(activeName);

  const filters = store((state) => state.filters);

  const selectedGenres = getSelectedGenres(filters, categories);

  const showExtraFilters = activeName === OFFCANVAS.SEARCH_FILTERS || activeName === OFFCANVAS.SAVED_LIST_FILTERS;
  const productTypeFilterValue = filters?.product_type?.value as ProductTypeFilterValue;
  const genres = productTypeFilterValue
    ? CATEGORIES[productTypeFilterValue]
    : uniqBy([...CATEGORIES[MAIN_CATEGORY.TV], ...CATEGORIES[MAIN_CATEGORY.MOVIES]], 'frontend');

  const handleGenre = (e) => {
    const genre = e.currentTarget.dataset.backend;
    const empty = e.currentTarget.dataset.empty;

    if (!genre) {
      applyFilters({ values: ['category_ids', []], store });
      return;
    }

    const category = categories.find((item) => item.name.toLowerCase() === genre);
    // empty fallback - don't show results for empty genre selected
    const categoryId = category?.id || empty;
    const categoryFilterValue = filters.category_ids.value as FilterArray;

    if (categoryFilterValue.includes(categoryId)) {
      const filtered = categoryFilterValue.filter((item) => item !== categoryId);
      applyFilters({ values: ['category_ids', filtered], store });
    } else {
      applyFilters({ values: ['category_ids', [...categoryFilterValue, categoryId]], store });
    }
  };

  const handleYear = useCallback(
    (value) => {
      applyFilters({ values: ['year_of_production', value], store });
    },
    [store],
  );

  const handleDuration = useCallback(
    (value) => {
      applyFilters({ values: ['duration', value], store });
    },
    [store],
  );

  const handleCategory = (e) => {
    const category = e.currentTarget.dataset.category;

    if (!category) {
      applyFilters({ values: ['product_type', ''], store });
      return;
    }

    applyFilters({ values: ['product_type', category], store });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mb-6">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <h3 className="mb-0">Filters</h3>
            <div className="d-inline-block pointer text-primary" data-bs-dismiss="offcanvas">
              <Icon name="x-lg" />
            </div>
          </div>
        </div>
      </div>

      <div
        className={cx(
          'row',
          { 'row-cols-1 row-cols-md-2': !showExtraFilters },
          { 'row row-cols-1 row-cols-md-2 row-cols-lg-4': showExtraFilters },
        )}
      >
        {showExtraFilters && (
          <>
            <div className="col mb-5 mb-lg-0">
              <h5 className="mb-5">Category</h5>
              <button
                className={cx('btn me-3 mb-3', {
                  'btn-outline-primary': filters.product_type.value,
                  'btn-primary': !filters.product_type.value,
                })}
                onClick={handleCategory}
              >
                Any
              </button>
              <button
                className={cx('btn me-3 mb-3', {
                  'btn-outline-primary': filters.product_type.value !== MAIN_CATEGORY.MOVIES,
                  'btn-primary': filters.product_type.value === MAIN_CATEGORY.MOVIES,
                })}
                data-category={MAIN_CATEGORY.MOVIES}
                onClick={handleCategory}
              >
                Movies
              </button>
              <button
                data-category={MAIN_CATEGORY.TV}
                className={cx('btn me-3 mb-3', {
                  'btn-outline-primary': filters.product_type.value !== MAIN_CATEGORY.TV,
                  'btn-primary': filters.product_type.value === MAIN_CATEGORY.TV,
                })}
                onClick={handleCategory}
              >
                Television
              </button>
            </div>
            <div className="col">
              <h5 className="mb-5">Genres</h5>
              <button
                className={cx('btn me-3 mb-3', {
                  'btn-outline-primary': (filters.category_ids?.value as FilterArray).length,
                  'btn-primary': !(filters.category_ids?.value as FilterArray).length,
                })}
                onClick={handleGenre}
              >
                Any
              </button>
              {genres.map((item, i) => {
                return (
                  <button
                    key={i}
                    data-backend={item.backend}
                    data-frontend={item.frontend}
                    data-empty={-i}
                    className={cx('btn me-3 mb-3', {
                      'btn-outline-primary': !selectedGenres.includes(item.frontend),
                      'btn-primary': selectedGenres.includes(item.frontend),
                    })}
                    onClick={handleGenre}
                  >
                    {item.frontend}
                  </button>
                );
              })}
            </div>
          </>
        )}
        <div className="col">
          <h5 className="mt-7 mb-5">Year</h5>
          <Ranger
            min={defaultYearStart}
            max={defaultYearEnd}
            values={[filters.year_of_production.value[0], filters.year_of_production.value[1]]}
            customTicks={customTicks(defaultYearStart, defaultYearEnd, 18)}
            onChange={handleYear}
          />
        </div>
        <div className="col">
          <h5 className="mt-7 mb-5">Runtime</h5>
          <Ranger
            min={defaultRuntimeStart}
            max={defaultRuntimeEnd}
            customTicks={customTicks(0, defaultRuntimeEnd)}
            values={[filters.duration.value[0], filters.duration.value[1]]}
            onChange={handleDuration}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 my-8">
          <hr />
        </div>
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <div>
              <button className="btn btn-lg btn-outline-primary" data-bs-dismiss="offcanvas">
                View Results
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
