import { action, IObservableArray, observable, toJS } from 'mobx';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'types';

export class ModalStore {
  @observable public modals = [] as IObservableArray;
  @observable public hideCallback = null;

  @action.bound
  public setHideCallback(callback) {
    this.hideCallback = callback;
  }

  @action.bound
  public updateModals(modals) {
    this.modals.replace(modals);
  }

  @action.bound
  public hide(modalId?, callback = null) {
    const modals = toJS(this.modals);
    const modal = modals.find(({ id }) => id === modalId) || modals[modals.length - 1];
    modal?.bootstrapModal?.hide();

    this.setHideCallback(callback);
  }

  @action.bound
  public open(name: Modal, { props = {}, modalOptions = {}, sizeClass = '' } = {}) {
    const id = uuidv4();

    const modal = {
      name,
      props,
      sizeClass,
      modalOptions,
      id,
      ref: React.createRef(),
      bootstrapModal: null,
    };

    this.updateModals([...this.modals, modal]);
  }
}
