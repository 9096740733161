import React, { useEffect, useRef, useState } from 'react';
import { Routes } from 'routes';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import cx from 'classnames';
import moment from 'moment';
import { toJS } from 'mobx';
import { resizedUrl, isImage, getThumbText, getType } from 'utils';
import ProductMetadata from './product-metadata';
import ProductBreadcrumbs from './product-breadcrumbs';
import { HorizontalThumbs } from '../index';
import { PRODUCTS_CLASSIFICATIONS, VIDEO_CLASSIFICATIONS } from 'types';
import { IVideoplayerApi, VideoPlayer } from '@mediafellows/videoplayer';
import './style.scss';
import { sendPagePing } from '../../utils/page-pings';
import ProductShowTitle from './product-show-title';

const urlParams = new URLSearchParams(window.location.search);
const sid = urlParams.get('sid');

const formatDuration = (period) => {
  const parts = [];
  const duration = moment.duration(period, 'seconds');

  if (!duration) return '';

  if (duration.hours() >= 1) {
    const hours = Math.floor(duration.hours());
    parts.push(`${hours} h`);
  }

  if (duration.minutes() >= 1) {
    const minutes = Math.floor(duration.minutes());
    parts.push(`${minutes} min`);
  }

  if (!parts.length && duration.seconds() >= 1) {
    const seconds = Math.floor(duration.seconds());
    parts.push(`${seconds} sec`);
  }

  return parts.join(' ');
};

export const humanizeDuration = (minutes, times?) => {
  if (!times) times = 1;
  const value = Number(minutes);

  if (value === 0) return '';
  if (value <= 30) return times === 1 ? 'Half Hour' : `${times} x Half Hour`;

  const multiplier = Math.ceil(value / 60);
  const words = {
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four',
    5: 'Five',
    6: 'Six',
    7: 'Seven',
    8: 'Eight',
    9: 'Nine',
    10: 'Ten',
  };

  const duration = multiplier === 1 ? `${words[multiplier]} Hour` : `${words[multiplier]} Hours`;

  return times === 1 ? duration : `${times} x ${duration}`;
};

const getProductThumbText = (asset) => {
  const { name, duration, classification } = asset || {};

  let title = asset?.name;
  let content = `${formatDuration(duration)}`;

  if (name.includes('Episode')) {
    content = name.replace('Episode', 'Ep');
  }

  if (name.includes('"')) {
    const regex = /"(.*?)"/g;
    const found = name.match(regex);

    if (found) {
      content = content.replace(found[0], '');
      content = `${content}, ${formatDuration(duration)}`;
      title = found[0];
    }
  } else {
    content = `${content}, ${VIDEO_CLASSIFICATIONS[classification]}`;
  }

  content = content.replace(' , ', ', ');

  return {
    title: content,
    content: title,
  };
};

interface IProductShow {
  recommendationProductId?: number;
  className?: string;
}

enum Tabs {
  FEATURED = 'featured',
  SCREENERS = 'screeners',
  TRAILERS = 'trailers',
}

export const ProductShow: React.FC<IProductShow> = observer(({ recommendationProductId }) => {
  const setSliderImages = useState([])[1];
  const [activeTab, setActiveTab] = useState(Tabs.SCREENERS);
  const player = useRef<HTMLDivElement & IVideoplayerApi>(null);

  const { productShowStore, recommendationsStore } = useStore();
  const { recommendation, products } = recommendationsStore;
  const {
    basicStore: { categories },
    routing,
    sessionStore: {
      user,
      session: { isAuthenticated },
    },
  } = useStore();

  const {
    product,
    marketingAssets,
    similarProducts,
    screeners,
    trailers,
    featuredVideo,
    seasons,
    loadAllVideos,
    loadOtherVideos,
  } = productShowStore;

  const [videoAsset, setVideoAsset] = useState(featuredVideo);
  const [videoAutoplay, setVideoAutoplay] = useState(false);
  const [assets, setAssets] = useState(screeners);
  const [activeSeason, setActiveSeason] = useState(seasons.length ? seasons[0] : null);
  const [isOtherVideosActive, setIsOtherVideosActive] = useState(false);
  const { default_layer, episodes_count } = product || {};
  const { duration, year_of_production } = default_layer || {};
  const fallbackPreview = product?.inherited_preview_image?.url;

  const customDuration = humanizeDuration(duration, episodes_count || 0);

  useEffect(() => {
    const assets = toJS(marketingAssets);
    let sliderImages;

    if (recommendationProductId) {
      sliderImages = products.filter(({ id }) => id === recommendationProductId);
    } else {
      sliderImages = assets.filter((item) => isImage(item));
    }

    sliderImages = sliderImages.map((item) => {
      const url = item?.preview_image?.url || item?.inherited_preview_image?.url;
      return resizedUrl(url, {
        size: 'hd',
      });
    });

    setSliderImages(sliderImages);
  }, [marketingAssets, products, recommendationProductId, setSliderImages]);

  if (!product) {
    return null;
  }

  const handleTabClick = (e) => {
    const tab = e.target.dataset.tab;
    if (tab === Tabs.TRAILERS) {
      setAssets(trailers);
    }

    if (tab === Tabs.SCREENERS) {
      setAssets(screeners);
    }

    setActiveTab(tab);
  };

  const handleSeasonChange = async (e) => {
    const id = e.currentTarget.id;
    const season = seasons.find((s) => s.id === Number(id));
    setActiveSeason(season);
    setIsOtherVideosActive(false);
    await loadAllVideos(id);
  };

  const handleOtherVideosClick = async () => {
    setIsOtherVideosActive(true);
    await loadOtherVideos();
  };

  const handleRecommendationClick = async (i, { id }) => {
    const url = sid
      ? `/recommendations/product/${id}/${recommendation.id}?sid=${sid}`
      : `/recommendations/product/${id}/${recommendation.id}`;

    routing.push(url);
  };

  const filteredRecommendations = products?.filter(
    (recoProduct) => recoProduct.id !== product.id && recommendation?.product_ids?.includes(recoProduct.id),
  );

  const recoRecipient = recommendation?.email_address;

  sendPagePing('visited', {
    entity_type: 'product',
    entity_id: product?.id,
  });

  return (
    <div className="product-show container-fluid--custom pt-13 pt-lg-14">
      <div>
        <ProductBreadcrumbs product={product} categories={categories} />
      </div>

      <ProductShowTitle duration={customDuration} product={product} className="d-lg-none" />
      <div className="row">
        <div className="col-12 col-lg-6 mt-lg-0 order-lg-1">
          {videoAsset && (
            <div className="position-relative">
              <VideoPlayer
                ref={player}
                video={videoAsset}
                autoplay={videoAutoplay}
                posterUrl={videoAsset?.preview_image?.url}
              >
                {!user?.disable_watermark && (
                  <>
                    {user && <span className="email-watermark">{user.email}</span>}
                    {!user && recommendationProductId && recoRecipient && (
                      <span className="email-watermark">{recoRecipient}</span>
                    )}
                  </>
                )}
              </VideoPlayer>
            </div>
          )}
          {!videoAsset && fallbackPreview && (
            <div className="text-center">
              <img src={fallbackPreview} className="img-fluid" alt={product?.title} />
            </div>
          )}
        </div>

        <div className="product-show__product-tabs col-12 d-flex align-items-center mb-1 mt-3 mt-lg-3 order-lg-3">
          <div
            className={cx('custom-link product__btn me-5', { 'product__btn--active': activeTab === Tabs.SCREENERS })}
            role="button"
            data-tab={Tabs.SCREENERS}
            onClick={handleTabClick}
          >
            Screeners
          </div>
          <div
            className={cx('custom-link product__btn', { 'product__btn--active': activeTab === Tabs.TRAILERS })}
            role="button"
            data-tab={Tabs.TRAILERS}
            onClick={handleTabClick}
          >
            Trailers & More
          </div>
        </div>

        <div className="col-12 d-flex align-items-center mt-1 mb-2 flex-wrap order-lg-5">
          {!!seasons.length &&
            seasons.map((season) => {
              const { sequence_number, id } = season;
              return (
                <div
                  onClick={handleSeasonChange}
                  id={id}
                  className={cx('custom-link product__btn me-5 mb-3', {
                    'product__btn--active': activeSeason?.id === id && !isOtherVideosActive,
                  })}
                  role={'button'}
                  key={id}
                >
                  Season {sequence_number}
                </div>
              );
            })}
          {PRODUCTS_CLASSIFICATIONS[product['@type']] && activeTab !== Tabs.SCREENERS && (
            <div
              onClick={handleOtherVideosClick}
              className={cx('custom-link product__btn me-5 mb-3', {
                'product__btn--active': isOtherVideosActive,
              })}
              role={'button'}
            >
              {PRODUCTS_CLASSIFICATIONS[product['@type']]}
            </div>
          )}
        </div>

        <div className="col-12 order-lg-last">
          {!Boolean(assets.length) && (
            <div className="mt-5">{`No ${activeTab === Tabs.SCREENERS ? 'screeners' : 'trailers'} found`}</div>
          )}

          {Boolean(assets.length) && (
            <HorizontalThumbs
              className="product__thumbs"
              scale="vga"
              onClick={(i, item) => {
                setVideoAutoplay(true);
                setVideoAsset(item);
              }}
              items={assets}
              texts={assets.map(getProductThumbText)}
            />
          )}
        </div>

        <div className="product__spacing d-block d-lg-none"></div>
        <div className="d-block d-lg-none"></div>

        <div className="col-12 col-lg-6 position-relative product-show__metadata-wrapper mt-0 mt-lg-0 order-lg-2">
          <ProductShowTitle duration={customDuration} product={product} className="d-none d-lg-block" />
          <ProductMetadata
            className="order-lg-2"
            data={product}
            year_of_production={year_of_production}
            productType={getType(product?.default_layer?.product_type).toLowerCase()}
          />
        </div>

        {recommendationProductId && Boolean(filteredRecommendations.length) && (
          <div className="col-12 mt-5 mt-lg-8 order-lg-last">
            <h3 className="text-capitalize mb-5">Other titles in this list</h3>
            <HorizontalThumbs
              scale="vga"
              onClick={handleRecommendationClick}
              items={filteredRecommendations}
              texts={filteredRecommendations.map(getThumbText)}
            />
          </div>
        )}

        {Boolean(similarProducts.length && isAuthenticated) && (
          <div className="product-show__recommendations col-12 mt-3 mt-lg-4 order-lg-last">
            <h3 className="text-capitalize mb-5">Recommended</h3>
            <HorizontalThumbs
              scale="vga"
              onClick={(i, item) => {
                routing.push(`${Routes.PRODUCT}/${item.id}`);
              }}
              items={similarProducts}
              texts={similarProducts.map(getThumbText)}
            />
          </div>
        )}
      </div>
    </div>
  );
});
