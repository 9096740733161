import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { start } from 'utils';
import { useStore } from 'store';
import { marketingAssetSchema, productSchema } from './schemas';
import { Loading, ProductShow } from 'components';

const Product: React.FC<{}> = observer(() => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const { productId } = useParams<{ productId: string }>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await start(store);
      const { productShowStore } = store;

      productShowStore.setSchemas({
        product: productSchema.replace('logline,', 'logline,product_type,rating,'),
        asset: marketingAssetSchema,
        marketingAsset: marketingAssetSchema,
      });

      productShowStore.init(Number(productId));

      const isSeries = await productShowStore.getProduct();
      await productShowStore.getMarketingAssets();

      if (!isSeries) {
        await productShowStore.loadScreeners();
        await productShowStore.loadTrailers();
      } else {
        await productShowStore.getTree();
        await productShowStore.getAllProductVideos();
        await productShowStore.loadAllVideos();
      }

      await productShowStore.getSimilarProducts();

      setLoading(false);
    })();
  }, [store, productId]);

  if (loading) {
    return (
      <section className="container pt-10">
        <div className="row">
          <Loading />
        </div>
      </section>
    );
  }

  return <ProductShow />;
});

export default Product;
