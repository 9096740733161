import { each, get, reverse, includes, map, merge, pick, sortBy } from 'lodash';
import { observable, action, runInAction, IObservableArray } from 'mobx';
import { IGetMarketingAssets } from '@mediafellows/tuco/dist/lib/tasks';
import { assetListSchema, productListSchema, productShowSchema } from '@mediafellows/tuco/dist/lib/schemas';

import { chipmunk, tuco, uniqBy, videoProgress } from 'utils';
import { productVideosAssetSchema } from 'pages/product/schemas';

export interface IStatefulArray extends IObservableArray {
  running: boolean;
}

export class ProductShowStore {
  @observable public seasons = [] as IObservableArray;
  @observable public trailers = [] as IObservableArray;
  @observable public screeners = [] as IObservableArray;
  @observable public products = [] as IObservableArray; // Check delete
  @observable public product: any;
  @observable public productId: number;
  @observable public marketingAssets = [] as IStatefulArray;
  @observable public similarProducts = [] as IObservableArray;
  @observable public isSeries = null;
  @observable public featuredVideo = null;
  @observable public allVideos = [] as IObservableArray;

  public schemas = {
    product: productShowSchema,
    asset: assetListSchema,
    marketingAsset: assetListSchema,
  };

  @action.bound
  public async getTree() {
    return chipmunk.run(async (chipmunk) => {
      const productPromise = await chipmunk.action('pm.product', 'get', { params: { product_ids: this.productId } });
      const ancestorsPromise = await chipmunk.action('pm.product', 'query_ancestors', {
        params: { product_id: this.productId },
      });
      const results = await Promise.all([ancestorsPromise, productPromise]);

      const tree = reverse(results[0].objects);
      tree.push(results[1].object);

      if (includes(['product/motion_picture/series', 'product/motion_picture/season'], results[1].object['@type'])) {
        const res = await this.getNextDescendants(results[1].object['@type']);
        tree.push([...reverse(res.objects)]);
      }

      const seasons = tree[1].filter((item) => item['@type'] === 'product/motion_picture/season');
      this.seasons.replace(sortBy(seasons, 'sequence_number'));
    });
  }

  @action.bound
  public async getProduct(id?) {
    return chipmunk.run(async (chipmunk) => {
      const productId = id ? id : this.productId;

      // needs to be a 'member.get' until this has been implemented: https://issues.mediapeers.com/issues/62216
      const product = (
        await chipmunk.action('pm.product', 'member.get', {
          params: { product_id: productId },
          schema: this.schemas.product
            .replace('product_state,', 'product_state, casts { name }, crews { name, role }, product_type,')
            .replace('preview_image', 'preview_image, parent { display_title, parent { display_title } }'),
          headers: { 'Mpx-Trace': 'true' },
        })
      ).object;

      this.isSeries = product?.['@type'] === 'product/motion_picture/series';
      this.product = product;
      return this.isSeries;
    });
  }

  @action.bound
  public async getProductsBy(ids?) {
    return chipmunk.run(async (chipmunk) => {
      const products = (
        await chipmunk.action('pm.product', 'get', {
          params: { product_ids: ids },
          schema: productListSchema,
        })
      ).objects;

      this.products.replace(products);
    });
  }

  @action.bound
  public async getMarketingAssets(productId = this.productId) {
    const opts: IGetMarketingAssets = {
      product_ids: productId,
      schema: this.schemas.marketingAsset,
    };
    this.marketingAssets.running = true;
    const data = await tuco('getMarketingAssets', opts);
    const { objects: assets = [] } = data || {};

    each(assets, (a) => {
      a.progress = videoProgress(get(a, 'duration'), get(a, 'video_continuation.last_timecode'));
    });

    this.marketingAssets.replace(assets);
    this.featuredVideo = assets.find((item) =>
      ['video/promo', 'video/trailer'].some((type) => type === item.classification),
    );
    this.marketingAssets.running = false;
  }

  @action.bound
  public init(productId: number) {
    runInAction(() => {
      this.clean();
      this.productId = productId;
    });
  }

  @action.bound
  public clean() {
    this.seasons.replace([]);
    this.products.replace([]);
    this.screeners.replace([]);
    this.trailers.replace([]);
    this.similarProducts.replace([]);
    this.product = undefined;
    this.productId = undefined;
    this.isSeries = null;
    this.featuredVideo = null;
    this.marketingAssets.replace([]);
  }

  @action.bound
  public getSimilarProducts() {
    if (!this.product) return;

    return chipmunk.run(async (chipmunk) => {
      const products = (
        await chipmunk.action('pm.product', 'search', {
          body: {
            sort: 'latest_release_date',
            order: 'desc',
            per: 7,
            search: {
              filters: [
                ['preview_asset_id', 'exist'],
                ['id', 'ne', this.product.id],
                ['type', 'eq', this.product['@type']],
                ['category_ids', 'in', this.product?.default_layer?.category_ids ?? []],
              ],
            },
          },
          schema: productListSchema.replace('product_state,', 'product_state,product_type,'),
        })
      ).objects;

      this.similarProducts.replace(products);
    });
  }

  private async getNextDescendants(type: string) {
    return chipmunk.run(async (chipmunk) => {
      const descType =
        type === 'product/motion_picture/series' ? 'product/motion_picture/season' : 'product/motion_picture/episode';
      const result = await chipmunk.action('pm.product', 'query_descendants', {
        params: { descType, product_id: this.productId, sort: 'flat_sequence_number', order: 'asc', to_depth: 1 },
        schema: `id, display_title, title, parent_id, sequence_number`,
      });

      return result;
    });
  }

  public setSchemas(schemas) {
    each(schemas, (schema, name) => (this.schemas[name] = schema));
  }

  @action.bound
  public async loadScreeners() {
    return chipmunk.run(async (chipmunk) => {
      const filters = [
        ['preview_image_id', 'exist'],
        ['classification', 'eq', 'video/screener'],
      ];

      const res = await chipmunk.unfurl('am.asset', 'search', {
        body: {
          product_ancestry_id: this.productId,
          search: {
            filters,
          },
        },
        schema: productVideosAssetSchema,
      });

      let videos = map(res.objects, (asset) => {
        return merge(
          {
            product_id: get(asset, 'products[0].product_id'),
            language_name: get(asset, 'languages[0].name'),
          },
          pick(asset, ['id', 'name', 'classification', 'duration', 'preview_image']),
        );
      });

      videos = sortBy(videos, ['product_id', 'name']);
      videos = uniqBy([...videos], 'id');

      if (!this.featuredVideo && videos.length) {
        this.featuredVideo = videos[0];
      }

      this.screeners.push(...videos);
    });
  }

  @action.bound
  public async loadTrailers() {
    return chipmunk.run(async (chipmunk) => {
      const filters = [
        ['preview_image_id', 'exist'],
        ['main_classification', 'eq', 'video'],
        ['classification', 'ne', 'video/screener'],
      ] as any;

      const res = await chipmunk.unfurl('am.asset', 'search', {
        body: {
          product_ancestry_id: this.productId,
          search: {
            filters,
          },
        },
        schema: productVideosAssetSchema,
      });

      let videos = map(res.objects, (asset) => {
        return merge(
          {
            product_id: get(asset, 'products[0].product_id'),
            language_name: get(asset, 'languages[0].name'),
          },
          pick(asset, ['id', 'name', 'classification', 'duration', 'preview_image']),
        );
      });

      videos = sortBy(videos, ['product_id', 'name']);

      this.trailers.push(...videos);
    });
  }

  @action.bound
  public async loadAllVideos(id = '') {
    return chipmunk.run(async (chipmunk) => {
      if (!this.seasons.length) {
        return;
      }

      const product_ancestry_id = id || this.seasons[0]?.id;

      const filters = [
        ['preview_image_id', 'exist'],
        ['main_classification', 'eq', 'video'],
      ];

      const res = await chipmunk.unfurl('am.asset', 'search', {
        body: {
          product_ancestry_id,
          search: {
            filters,
          },
        },
        schema: productVideosAssetSchema,
      });

      let videos = map(res.objects, (asset) => {
        return merge(
          {
            product_id: get(asset, 'products[0].product_id'),
            language_name: get(asset, 'languages[0].name'),
          },
          pick(asset, ['id', 'name', 'classification', 'duration', 'preview_image']),
        );
      });

      if (!this.featuredVideo) {
        this.featuredVideo = videos.find((item) =>
          ['video/promo', 'video/trailer'].some((type) => type === item.classification),
        );
      }

      videos = sortBy(videos, ['product_id', 'name']);
      this.screeners.replace(videos.filter((video) => video.classification === 'video/screener'));
      this.trailers.replace(videos.filter((video) => video.classification !== 'video/screener'));
    });
  }
  @action.bound
  public async getAllProductVideos() {
    return chipmunk.run(async (chipmunk) => {
      const product_ancestry_id = this.productId;

      const filters = [
        ['preview_image_id', 'exist'],
        ['main_classification', 'eq', 'video'],
      ];

      const res = await chipmunk.unfurl('am.asset', 'search', {
        body: {
          product_ancestry_id,
          search: {
            filters,
          },
        },
        schema: productVideosAssetSchema,
      });

      let videos = map(res.objects, (asset) => {
        return merge(
          {
            product_id: get(asset, 'products[0].product_id'),
            language_name: get(asset, 'languages[0].name'),
          },
          pick(asset, ['id', 'name', 'classification', 'duration', 'preview_image']),
        );
      });

      videos = sortBy(videos, ['product_id', 'name']);
      this.allVideos.replace(videos);
    });
  }

  @action.bound
  public async loadOtherVideos() {
    const promises = [];
    const screenerOtherVideos = [];
    const trailerOtherVideos = [];
    const filters = [
      ['preview_image_id', 'exist'],
      ['main_classification', 'eq', 'video'],
    ];

    this.seasons.map((season) =>
      promises.push(
        chipmunk.unfurl('am.asset', 'search', {
          body: {
            product_ancestry_id: season.id,
            search: {
              filters,
            },
          },
          schema: productVideosAssetSchema,
        }),
      ),
    );
    const data = await Promise.all(promises);
    const seasonVideosIds = data.flatMap((item) => item.objects).map((item) => item.id);
    const seriesOnlyVideos = this.allVideos.filter((video) => !seasonVideosIds.includes(video.id));
    let videos = map(seriesOnlyVideos, (asset) => {
      return merge(
        {
          product_id: get(asset, 'products[0].product_id'),
          language_name: get(asset, 'languages[0].name'),
        },
        pick(asset, ['id', 'name', 'classification', 'duration', 'preview_image']),
      );
    });

    videos = sortBy(videos, ['product_id', 'name']);
    videos.map((video) => {
      console.log(video.classification);
      if (video.classification === 'video/screener') {
        return screenerOtherVideos.push(video);
      }
      return trailerOtherVideos.push(video);
    });

    this.screeners.replace(screenerOtherVideos);
    this.trailers.replace(trailerOtherVideos);
  }
}
