import { chipmunk, tuco } from 'utils';
import { IGetPurposeGroups } from '@mediafellows/tuco/dist/lib/tasks';
import { productListSchema } from '@mediafellows/tuco/dist/lib/schemas';

const sorting = [
  'tv_drama_featured',
  'tv_comedy_featured',
  'tv_unscripted_featured',
  'tv_eventseries_featured',
  'tv_event series_featured',
  'tv_kids_featured',
  'tv_formats_featured',
  'movies_drama_featured',
  'movies_comedy_featured',
  'movies_action_featured',
  'movies_family_featured',
  'movies_thriller_featured',
  'movies_horror_featured',
  'movies_documentary_featured',
];

export const fetchProductsByGroup = () => {
  return chipmunk.run(
    async () => {
      const result = [];
      const promises = [];

      const data = await tuco('getPurposeGroups', {
        purposeRegex: '^(movies|tv)_',
        schema: 'id, name, purpose',
      } as IGetPurposeGroups);

      const { objects: groups = [] } = data || {};

      const filtered = groups.filter(({ purpose }) => !['tv_featured', 'movies_featured'].includes(purpose));

      filtered.map(({ purpose }) => {
        promises.push(tuco('getPurposeGroupProducts', { purpose, schema: productListSchema }));
      });

      const results = await Promise.all(promises);

      filtered.map(({ purpose }, index) => {
        if (results[index]?.objects?.length) {
          result.push({ group: purpose, products: results[index].objects });
        }
      });

      return result.sort((a, b) => {
        const aSorting = sorting.indexOf(a?.group || '');
        const bSorting = sorting.indexOf(b?.group || '');
        return aSorting - bSorting;
      });
    },
    (e: Error) => {
      console.log('Failed to fetch products by group', e);
    },
  );
};
