import React, { useCallback, useEffect, useState } from 'react';
import { AccountSidemenu, Loading, ProductList, TopSection } from 'components';
import { start } from 'utils';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Modal } from 'types';
import './style.scss';

export const ContinueWatching = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const {
    modalStore: { open },
    homeStore: { continueWatching },
  } = store;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await start(store);
      await store.homeStore.loadContinueWatching();
      setIsLoading(false);
    })();
  }, [store]);

  const handleContinueWatching = useCallback(
    (id) => {
      const product = continueWatching.find((item) => item.id === Number(id));

      open(Modal.PREVIEW_ASSETS, {
        modalOptions: { backdrop: 'static' },
        props: { product },
        sizeClass: 'modal-xl',
      });
    },
    [open, continueWatching],
  );

  return (
    <>
      <TopSection title={'Continue Watching'} isSmall={true} />
      <div className="container-fluid--custom">
        <AccountSidemenu />
        <div className="row">
          {isLoading && (
            <div className="col-12">
              <Loading />
            </div>
          )}
          {!isLoading && (
            <div className="col-12 col-md-8">
              <ProductList
                showProgress={true}
                columnsClass={'col-6 col-md-4 col-xl-3'}
                products={continueWatching}
                total={continueWatching.length}
                containerClass={''}
                onClick={handleContinueWatching}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
});
