import React, { useState, useRef, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { Thumbnail, thumbText } from '../thumbnail/thumbnail';
import { previewImage } from '@mediafellows/tuco/dist/lib/helpers';
import { ImgResizerSizes, isVideo } from 'utils';
import './style.scss';
import { useSize } from 'utils/hooks/use-size';
// import ProductFavorites from '../product-show/product-favorites';

interface HorizontalThumbsProps {
  items: any[];
  onClick?: (index: number, item: any, scrollToPreview?: boolean) => void;
  texts?: thumbText[];
  singleThumb?: boolean;
  showVideoProgress?: boolean;
  active?: any;
  keyPrefix?: string;
  disableActions?: boolean;
  activeClass?: string;
  className?: string;
  thumbClass?: string;
  scale?: ImgResizerSizes;
  align?: 'center' | 'left' | 'right';
}

export const RightArrow = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
);

export const LeftArrow = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </svg>
);

const HorizontalThumbs: React.FC<HorizontalThumbsProps> = (props) => {
  const {
    items,
    onClick,
    texts,
    singleThumb,
    active,
    scale,
    align = 'left',
    keyPrefix = '',
    showVideoProgress,
    activeClass,
    className,
    disableActions = false,
  } = props;
  const [currentThumb, setCurrentThumb] = useState<number>(0);
  const [isScrollVisible, setIsScrollVisible] = useState(true);
  const thumbsRef = useRef<HTMLDivElement>(null);
  const size = useSize(thumbsRef) as { width?: number };
  const isMobile = size?.width < 480;
  const isSingle = singleThumb || isMobile;

  // detect if scroll is visible on size change
  useEffect(() => {
    if (!size || !thumbsRef.current) {
      return;
    }

    const isVisible = thumbsRef.current.scrollWidth > thumbsRef.current.offsetWidth;

    if (isVisible !== isScrollVisible) {
      setIsScrollVisible(isVisible);
    }
  }, [size, thumbsRef, isScrollVisible, items]);

  useEffect(() => {
    if (!items?.length) {
      return;
    }

    if (!items[currentThumb]) {
      setCurrentThumb(0);
    }
  }, [items, currentThumb]);

  useEffect(() => {
    if (!items?.length) {
      return;
    }

    const index = items.findIndex((item) => item.id === active?.id);

    if (index !== -1) {
      setCurrentThumb(index);
    }
  }, [active, items]);

  // handle scroll to current thumb for horizontal mode
  useEffect(() => {
    if (!thumbsRef.current) {
      return;
    }

    const current = thumbsRef.current.querySelector(`div[data-index="${currentThumb}"]`) as HTMLDivElement;

    thumbsRef.current.scrollTo({
      top: 0,
      left: current?.offsetLeft,
      behavior: 'smooth',
    });
  }, [currentThumb, items]);

  const updateCurrentThumb = useCallback((index: number): void => {
    setCurrentThumb(index);
  }, []);

  const handleThumbClick = useCallback(
    (e): void => {
      const index = e.currentTarget.dataset.index;

      updateCurrentThumb(Number(index));

      if (onClick) {
        onClick(Number(index), items[index], true);
      }
    },
    [updateCurrentThumb, onClick, items],
  );

  if (!items.length) {
    return null;
  }

  const singleThumbSize = items.length > 1 ? size?.width - 23 : size?.width;

  return (
    <div
      className={cx(
        'horizontal-thumbs',
        `horizontal-thumbs--align-${align}`,
        { 'horizontal-thumbs--single': singleThumb },
        { 'horizontal-thumbs--mobile': isMobile },
        { 'horizontal-thumbs--scroll-hidden': !isScrollVisible },
        { 'horizontal-thumbs--multiple': !singleThumb },
        className,
      )}
    >
      <div className="horizontal-thumbs__thumbnails" ref={thumbsRef}>
        {items.map((item, index) => {
          return (
            <div
              className={cx('horizontal-thumbs__thumb', {
                'horizontal-thumbs__thumb--disabled': disableActions,
                'horizontal-thumbs__thumb--current': currentThumb === index && !disableActions,
                'horizontal-thumbs__thumb--active': active?.id === item.id,
                'horizontal-thumbs__thumb--video': isVideo(item),
                [activeClass]: activeClass && active?.id === item.id,
              })}
              style={{ width: isSingle ? singleThumbSize / 2 : undefined }}
              key={`${keyPrefix}-${index}`}
              data-index={index}
              onClick={handleThumbClick}
            >
              <Thumbnail
                assetType={item['@type']}
                scale={scale}
                image={previewImage(item)?.url}
                text={texts?.[index]}
                showOverlay={false}
                showProgress={showVideoProgress && isVideo(item)}
                progress={item?.progress}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(HorizontalThumbs);
