import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ButtonSpinner, FormBox } from 'components';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useStore } from 'store';
import { displayError, rememberedLocation, restoreLocation, sendPagePing } from 'utils';
import cx from 'classnames';
import { Routes } from 'routes';

export const SignIn = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { sessionStore, toastStore, basicStore } = useStore();

  const signIn = async ({ email, password }): Promise<void> => {
    setIsLoading(true);

    try {
      const session = await sessionStore.login(email, password);

      if (session?.mfa_check_required) {
        return;
      }

      await basicStore.loadBasics(true);
      await sessionStore.loadUser(true);
      await sendPagePing('logged-in');

      if (rememberedLocation()) {
        restoreLocation();
      }
    } catch (err) {
      setIsLoading(false);
      // TODO: Fix me in backend
      const errorText =
        err?.text && err?.text.includes('Your account is blocked.')
          ? "To keep your account safe, we kindly ask that you update your password. Please click 'Forgot Password?' to start the process to reset."
          : err?.text;

      toastStore.error(errorText || 'Failed to sign in');
    }
  };

  return (
    <FormBox colClass="col-12 col-md-6 col-xl-5 col-xxl-4 mt-13">
      <form className="row g-3" noValidate={true} onSubmit={handleSubmit(signIn)}>
        <div className="col-12 mb-3">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <input
            id="email"
            placeholder={'Ex. johnsmith@isp.com'}
            type="email"
            {...register('email', {
              required: true,
              minLength: 8,
            })}
            autoComplete={'email'}
            className={cx('form-control', { 'is-invalid': errors?.email })}
          />
          {displayError(errors?.email, 'Email')}
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            id="password"
            placeholder={'Password *'}
            type="password"
            {...register('password', {
              required: true,
            })}
            autoComplete="off"
            className={cx('form-control', { 'is-invalid': errors?.password })}
          />
          {displayError(errors?.password, 'Password')}
          <div className="d-flex justify-content-end">
            <Link className="small mt-1" to={Routes.FORGOTTEN_PASSWORD}>
              Forgot?
            </Link>
          </div>
        </div>
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center flex-column">
            <div>
              <button type="submit" disabled={isLoading} className="btn btn-lg btn-outline-primary">
                <ButtonSpinner title="Log In" isLoading={isLoading} />
              </button>
            </div>
            <Link className="custom-link mt-4" to={Routes.REGISTRATION}>
              Request Access
            </Link>
          </div>
        </div>
      </form>
    </FormBox>
  );
});
