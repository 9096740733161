import { SessionStore } from './session-store';
import { RouterStore } from 'mobx-react-router';
import { ToastStore } from './toast-store';
import { BasicsStore } from './basic-store';
import { GroupsStore } from 'store/groups-store';
import { ProductShowStore } from 'store/./product-show-store';
import { RecommendationsStore } from 'store/recommendations-store';
import { ModalStore } from 'store/modal-store';
import { ConferenceStore } from '@mediafellows/react-video-conference';
import { chipmunk } from 'utils';
import { HomeStore } from 'store/home-store';

interface ILocationState {
  location: {
    state: any;
  };
}

export class RootStore {
  public routing: RouterStore & ILocationState;
  public sessionStore: SessionStore;
  public productShowStore: ProductShowStore;
  public toastStore: ToastStore;
  public basicStore: BasicsStore;
  public groupsStore: GroupsStore;
  public homeStore: HomeStore;
  public modalStore: ModalStore;
  public recommendationsStore: RecommendationsStore;
  public conferenceStore: ConferenceStore;

  constructor() {
    this.routing = new RouterStore();
    this.sessionStore = new SessionStore(this);
    this.toastStore = new ToastStore();
    this.basicStore = new BasicsStore();
    this.groupsStore = new GroupsStore();
    this.productShowStore = new ProductShowStore();
    this.modalStore = new ModalStore();
    this.recommendationsStore = new RecommendationsStore(this);
    this.conferenceStore = new ConferenceStore(chipmunk);
    this.homeStore = new HomeStore();
  }
}
