import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ButtonSpinner, Icon } from 'components';
import { useStore } from 'store';

interface ConfirmationModalProps {
  title?: string;
  cancelText?: string;
  hasCancelButton?: boolean;
  bodyText?: string;
  confirmText?: string;
  confirmAction?: () => Promise<boolean>;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = observer(
  ({ title, bodyText, confirmText = 'Confirm', confirmAction, cancelText = 'Cancel', hasCancelButton = true }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { modalStore } = useStore();

    const handleConfirm = async () => {
      setIsLoading(true);

      const result = await confirmAction?.();

      if (result) {
        modalStore.hide();
      }

      setIsLoading(false);
    };

    return (
      <>
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <div className="close" data-bs-dismiss="modal" aria-label="Close">
            <Icon name="x-lg" />
          </div>
        </div>
        <div className="modal-body px-7 text-center">{bodyText}</div>
        <div className="modal-footer d-flex justify-content-center">
          <div className="d-flex justify-content-center flex-column text-center">
            <button disabled={isLoading} onClick={handleConfirm} className="btn btn-lg btn-outline-primary">
              <ButtonSpinner title={confirmText} isLoading={isLoading} />
            </button>
            {hasCancelButton && (
              <a href="#" className="custom-link my-4" data-bs-dismiss="modal" onClick={(e) => e.preventDefault()}>
                {cancelText}
              </a>
            )}
          </div>
        </div>
      </>
    );
  },
);

export default ConfirmationModal;
