import React, { useCallback, useRef } from 'react';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { isVideo } from 'utils';
import { VideoPlayer, IVideoplayerApi } from '@mediafellows/videoplayer';
import { Thumbnail } from '../../thumbnail/thumbnail';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';

export const PreviewAssetModal: React.FC<{ product: any }> = observer(({ product }) => {
  const player = useRef<HTMLDivElement & IVideoplayerApi>(null);
  const asset = product?.asset;

  const {
    sessionStore: { user },
  } = useStore();

  const onInitialized = useCallback((): void => {
    if (product?.timecode && player?.current) {
      player.current.seek(product.timecode, true);
    }
  }, [product]);

  return (
    <div className="modal-body">
      <div className="d-flex align-items-center mb-3">
        <div className="flex-1 me-3 text-white">
          <Link className="text-decoration-none" to={`${Routes.PRODUCT}/${product.id}`}>
            <h3 className="text-truncate mb-3">{product?.display_title}</h3>
          </Link>
          <p className="mb-0">{asset?.name}</p>
        </div>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" data-bs-dismiss="modal" />
      </div>
      {asset && isVideo(asset) && (
        <div className="position-relative">
          <VideoPlayer ref={player} video={asset} onInitialized={onInitialized} autoplay={true}>
            {user && !user?.disable_watermark && <span className="email-watermark">{user.email}</span>}
          </VideoPlayer>
        </div>
      )}

      {asset && !isVideo(asset) && <Thumbnail showOverlay={false} image={asset.preview_image.url} />}
    </div>
  );
});
