import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'store';
import { routes, useGoToConferenceRoute } from '@mediafellows/react-video-conference';

export const Ending: FC = observer(() => {
  const { sessionStore, conferenceStore, modalStore } = useStore();
  const { isPublic } = sessionStore.session;
  const { conference } = conferenceStore;
  const { farewell_text, status, farewell_alt_text } = conference || {};
  const conferenceRunning = status === 'running';
  const goToConferenceRoute = useGoToConferenceRoute();

  const handleRejoin = (): void => {
    modalStore.hide();
    goToConferenceRoute(routes.HOME);
  };

  return (
    <main>
      <div className="chime__container--with-spacing chime__container--centered">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <img
                loading="lazy"
                className="form-box__logo mt-10"
                src="/static-assets/img/logo-distribution.svg"
                alt="Lionsgate"
              />
              <div className="row justify-content-center mt-10">
                <div className="col-10">
                  <div className="chime__container--with-spacing chime__container--centered">
                    <h1 className="chime__header h2 fw-bolder">{conference.title}</h1>

                    {isPublic && <div className="h3 mt-7" dangerouslySetInnerHTML={{ __html: farewell_alt_text }} />}
                    {!isPublic && <div className="h3 mt-7" dangerouslySetInnerHTML={{ __html: farewell_text }} />}

                    {conferenceRunning && (
                      <button className="chime__button--main btn btn-outline-primary btn-lg" onClick={handleRejoin}>
                        Rejoin Meeting
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});

Ending.displayName = 'Ending';

export default Ending;
