import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from 'react-router';
import { setRootStore, storeContext } from 'store';
import { RootStore } from 'store/root-store';
import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import { AppRoutes, ScrollToTop } from 'routes';
import { PlayerConfig } from '@mediafellows/videoplayer';
import { chipmunk, visitId, visitorId } from 'utils';
import { ToastManager, ModalManager, Offcanvas } from 'components';
import './assets/styles/main.scss';

PlayerConfig.configure({
  chipmunk: () => chipmunk,
  analytics: true,
  visitId: () => visitId(),
  visitorId: () => visitorId(),
});

const browserHistory = createBrowserHistory();

export const StoreProvider = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
  const store = setRootStore(new RootStore());
  const history = syncHistoryWithStore(browserHistory, store.routing);

  return (
    <storeContext.Provider value={store}>
      <Router history={history}>
        <ScrollToTop />
        {children}
      </Router>
    </storeContext.Provider>
  );
};

const App = (
  <StoreProvider>
    <AppRoutes />
    <ModalManager />
    <ToastManager />
    <Offcanvas />
  </StoreProvider>
);

ReactDOM.createRoot(document.getElementById('app')).render(App);
