import { observable, action } from 'mobx';
import { chipmunk, cookie, getSession, VISIT_KEY } from 'utils';
import { rememberLocation } from 'utils/path';
import { Routes } from 'routes';
import { RootStore } from 'store/root-store';
import pigeonConnect from 'utils/pigeon';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { Session } from 'types';
import { addPurpose } from 'utils/add-purpose';

export class SessionStore {
  @observable public session = null;
  @observable public initialLoadDone = false;
  @observable public user = null;
  @observable public is2FArequired = false;

  private sessionPromise = null;
  private userPromise = null;
  private root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
  }

  @action
  public async loadSession(force = false): Promise<Session> {
    return chipmunk.run(
      async () => {
        if (!force && this.sessionPromise) {
          return this.sessionPromise;
        }

        // wait for the pending request to finish, unfortunately we cannot cancel this
        try {
          await this.sessionPromise;
        } catch {}

        const promise = async () => {
          this.session = await getSession();

          this.is2FArequired = this.session.mfa_check_required;
          const purpose = this.session?.user?.mfa_auth_method;

          if (purpose) {
            addPurpose(purpose);
          }

          this.initialLoadDone = true;

          if (!this.is2FArequired) {
            pigeonConnect(this.session.user?.id);
          }

          return this.session;
        };

        return (this.sessionPromise = promise());
      },
      async (err) => {
        const status = err.status || err.statusCode;
        const config = chipmunk.currentConfig();
        const hasSessionHeader = config?.headers?.['Session-Id'];

        if (hasSessionHeader && (status === 419 || status === 404)) {
          this.clear();
          await this.loadSession();
          return;
        }

        const routing = this.root.routing;
        rememberLocation(routing.location);
        routing.push(Routes.HOME);

        this.initialLoadDone = true;
        this.root.toastStore.error(err.description || 'Failed to load session');
        return null;
      },
    );
  }

  @action
  login(email, password): Promise<Session> {
    return chipmunk.run(async () => {
      chipmunk.updateConfig({
        headers: {
          'Session-Id': null,
          'Role-Id': null,
        },
      });

      const device_id = await getFingerprint();

      const res = await chipmunk.action('um.session', 'create', {
        body: { email, password, device_id },
      });

      cookie.set('sessionId', res.object.id);

      chipmunk.updateConfig({
        headers: { 'Session-Id': res.object.id },
      });

      return this.loadSession(true);
    });
  }

  @action.bound
  public async loadUser(reload = false) {
    return chipmunk.run(async (chipmunk) => {
      if (this.userPromise && !reload) return this.userPromise;
      if (!this.session?.user?.id) return;

      this.userPromise = chipmunk
        .action('um.user', 'get', {
          params: { user_ids: this.session.user.id },
          schema: `id, address, first_name, last_name, email, gender, title, function, phones, interests, organization { addresses, name }, skip_marketing_emails, preview_image, responsible_user_id, disable_watermark`,
        })
        .then((result) => {
          return (this.user = result.object);
        })
        .catch(() => null);
    });
  }

  @action.bound
  public async logout(): Promise<void> {
    this.clear();
    await this.loadSession();
  }

  @action.bound
  public clear(): void {
    this.session = null;
    this.resetPromises();

    cookie.remove('sessionId');
    cookie.remove(VISIT_KEY);

    chipmunk.updateConfig({
      headers: {
        'Session-Id': null,
        'Role-Id': null,
      },
    });
  }

  @action.bound
  public resetPromises() {
    console.log('reset promises');
    this.userPromise = null;
    this.sessionPromise = null;
  }
}
