import { nanoid } from 'nanoid';
import { omit, get } from 'lodash';
import { chipmunk } from 'utils/chipmunk';
import { getRootStore } from 'store';
import { cookie } from 'utils/cookie';

declare let AFFILIATION_ID;

export const VISITOR_KEY = 'visitor-id';
export const VISITOR_TTL = 10 * 365 * 24 * 60 * 60; // 10 years

export const VISIT_KEY = 'visit-id';
export const VISIT_TTL = 1 * 365 * 24 * 60 * 60; // 1 year

interface IContext {
  affiliation_id: string;
  visitor_id: string;
  visit_id: string;
  entity_type: string;
  entity_id: number;
  domain: string;
  total_duration: number;
}

let initTimeout;
let pingInterval;
let context: IContext;

const send = async (context: IContext, dt: number) => {
  return chipmunk.run(
    async (chipmunk) => {
      const user_id = get(getRootStore(), 'sessionStore.userId');

      await chipmunk.action('ac.virtual/page_ping', 'ping', {
        body: {
          ...omit(context, 'total_duration'),
          user_id,
          dt,
        },
      });
    },
    () => {
      console.warn('failed ping', context);
    },
  );
};

export const id = (key: string, expires: number): string => {
  let value = cookie.get(key);

  if (!value) {
    value = nanoid();
    cookie.set(key, value, { path: '/', expires });
  }

  return value;
};

export const visitorId = (): string => {
  return id(VISITOR_KEY, VISITOR_TTL);
};

export const visitId = (): string => {
  return id(VISIT_KEY, VISIT_TTL);
};

export const stopPinging = () => {
  clearTimeout(initTimeout);
  clearInterval(pingInterval);
};

export const sendPagePing = async (verb: string, extraFields?: { [key: string]: any }): Promise<any> => {
  const visitor_id = id(VISITOR_KEY, VISITOR_TTL);
  const visit_id = id(VISIT_KEY, VISIT_TTL);
  const domain = window.location.host.split(':').shift();
  const user_id = get(getRootStore(), 'sessionStore.userId');

  let body = {
    verb,
    domain,
    visit_id,
    visitor_id,
    entity_type: 'site',
    entity_id: 0,
    affiliation_id: AFFILIATION_ID,
    group_id: null,
    dt: 1,
    ...(extraFields ? extraFields : {}),
  };

  if (user_id) body = { ...body, ...{ user_id } };

  return chipmunk.run(async (chipmunk) => {
    await chipmunk.action('ac.virtual/page_ping', 'ping', { body, proxy: false });
  });
};

export const startPinging = (entity_type: 'product', entity_id: number) => {
  stopPinging();

  const visitor_id = id(VISITOR_KEY, VISITOR_TTL);
  const visit_id = id(VISIT_KEY, VISIT_TTL);
  const domain = window.location.host.split(':').shift();

  context = { visitor_id, visit_id, domain, entity_type, entity_id, total_duration: 0, affiliation_id: AFFILIATION_ID };

  initTimeout = setTimeout(() => {
    send(context, 1);

    pingInterval = setInterval(() => {
      context.total_duration += 10;

      if (context.total_duration > 600) {
        stopPinging();
        return;
      }

      send(context, 10);
    }, 10000);
  }, 1000);
};
