import { chipmunk } from 'utils';

export const fetchProductdIds = async (id: string, baskets: any[]) => {
  let productIds = [];
  const promises = [];

  if (id === 'favorites') {
    baskets.map(({ id: basket_id }) => {
      promises.push(
        chipmunk.action('pm.basket', 'query_products', {
          params: { basket_id },
          schema: `id`,
        }),
      );
    });

    const data = await Promise.all(promises);
    const results = data.map((item) => item.objects);

    productIds = results.flat().map((item) => item.id);
  } else {
    const result = await chipmunk.action('pm.basket', 'query_products', {
      params: { basket_id: id },
      schema: `id`,
    });

    productIds = (result?.objects || []).map((item) => item.id);
  }
  return [...new Set(productIds)];
};
