import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import React, { useEffect } from 'react';
import { Toast } from 'bootstrap';
import cx from 'classnames';
import { useStore } from 'store';
import './style.scss';

const ToastManager = observer(() => {
  const {
    toastStore: { updateToasts, toasts },
  } = useStore();
  const container = React.useRef(null);

  useEffect(() => {
    const containerEl = container.current;

    const handleHidden = (e) => {
      const id = e.target.dataset.id;
      const newToasts = toasts.filter((t) => t.id !== id);
      updateToasts(newToasts);
    };

    containerEl.addEventListener('hidden.bs.toast', handleHidden);
    return () => {
      containerEl.removeEventListener('hidden.bs.toast', handleHidden);
    };
  }, [toasts, updateToasts]);

  useEffect(() => {
    autorun(() => {
      let hasUpdate = false;

      const newToasts = toasts.map((toast) => {
        const toastEl = toast.ref.current;

        if (!toast.bootstrapToast && toastEl) {
          toast.bootstrapToast = new Toast(toastEl, toast?.options);
          toast.bootstrapToast.show();
          hasUpdate = true;
        }

        return toast;
      });

      if (hasUpdate) {
        updateToasts(newToasts);
      }
    });
  }, [toasts, updateToasts]);

  return (
    <div className="toast-manager position-fixed top-0 end-0 p-3" ref={container}>
      {toasts.map(({ content, id, ref, type }) => {
        const isDanger = type === 'danger';
        const isSuccess = type === 'success';
        return (
          <div
            className={cx(
              'toast toast align-items-center text-white border-0 mb-3',
              { 'bg-success': isSuccess },
              { 'bg-danger': isDanger },
            )}
            key={id}
            ref={ref}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-id={id}
          >
            <div className="d-flex">
              <div className="toast-body">{content}</div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default ToastManager;
