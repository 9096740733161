import { observable, action, IObservableArray } from 'mobx';
import { IGetPurposeGroupEntities } from '@mediafellows/tuco/dist/lib/tasks';
import { assetListSchema } from '@mediafellows/tuco/dist/lib/schemas';
import { tuco, chipmunk } from 'utils';
import { isArray, map, compact } from 'lodash';

export class GroupsStore {
  @observable public purposeGroupProducts = [] as IObservableArray;
  @observable public group: any;
  @observable public groupAssets = [] as IObservableArray;

  public schemas = {
    asset: assetListSchema,
  };

  @action.bound
  public async getPurposeGroupProducts(options: IGetPurposeGroupEntities) {
    const data = await tuco('getPurposeGroupProducts', options as IGetPurposeGroupEntities);
    const { objects = [] } = data || {};
    this.purposeGroupProducts.replace(objects);
  }

  @action.bound
  public async getPurposeGroup(purpose) {
    return chipmunk.run(async (chipmunk) => {
      const result = await chipmunk.action('um.group', 'get_purpose', {
        params: { purpose },
        schema: `
          id, name, description, preview_image, external_reference, purpose,
        `,
      });

      this.group = result.object;
    });
  }

  @action.bound
  public async getGroupAssets(groupIdorIds) {
    return chipmunk.run(async (chipmunk) => {
      const group_ids = isArray(groupIdorIds) ? groupIdorIds : [groupIdorIds];

      const params = {
        group_ids,
        per: 250,
        sort: 'sequence_number',
        order: 'asc',
        type: 'group/item/asset',
      };

      const items = (
        await chipmunk.action('am.group/item', 'query', {
          params,
          schema: `asset { ${assetListSchema} }`,
        })
      ).objects;

      this.groupAssets.replace(compact(map(items, 'asset')));
    });
  }
}
