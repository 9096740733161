import { getRootStore } from 'store';
import { addPurpose } from 'utils/add-purpose';

const getPurpose = (obj) => {
  const purpose = obj?.payload?.purpose;

  if (purpose) {
    return purpose;
  }

  try {
    return getPurpose(JSON.parse(obj.error.response.text));
  } catch (e) {
    console.log('Failed to get purpose', e);
  }
};

export const errorHandler2FA = (e) => {
  const { sessionStore, toastStore } = getRootStore();

  const purpose = getPurpose(e.object);

  if (purpose) {
    addPurpose(purpose);
  }

  if (sessionStore.is2FArequired) {
    return;
  }

  toastStore.error('Two Factor Authentication is Required');
  sessionStore.is2FArequired = true;
};
