import React from 'react';
import { Icon } from 'components';
import { observer } from 'mobx-react-lite';
import { Routes } from 'routes';
import { useStore } from 'store';
import { MAIN_CATEGORY } from 'types';
import config from '../../config';

export const SidebarContent: React.FC<{ toggleOffcanvas?: (e: any) => void }> = observer(() => {
  const yearStart = 2022;
  const yearNow = new Date().getUTCFullYear();
  const yearText = yearNow > yearStart ? yearNow : yearStart;
  const store = useStore();
  const { routing } = store;

  return (
    <div className="sidebar-content">
      <div className="d-flex justify-content-end">
        <div className="d-inline-block pointer text-primary" data-bs-dismiss="offcanvas">
          <Icon name="x-lg" />
        </div>
      </div>
      <div className="row d-flex flex-column justify-content-between">
        <div className="col">
          <div className="offcanvas-body p-0">
            <div className="mt-5">
              <div
                className="offcanvas-action text-uppercase mb-6 h4"
                data-offcanvas={MAIN_CATEGORY.MOVIES}
                onClick={() => routing.push(Routes.MOVIES)}
              >
                Movies
              </div>
              <div
                className="offcanvas-action text-uppercase my-6 h4"
                data-offcanvas={MAIN_CATEGORY.TV}
                onClick={() => routing.push(Routes.TV)}
              >
                Television
              </div>
              <div className="offcanvas-action text-uppercase my-6 h4" onClick={() => routing.push(Routes.SAVED_LISTS)}>
                Saved Lists
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="small text-muted mt-6 text-center">
            ©{yearText} Lionsgate Entertainment, All Rights Reserved.
          </div>

          <div className="d-flex justify-content-center mt-9">
            <a className="custom-link" href={config.socialLinks.facebook} rel="noreferrer noopener" target="_blank">
              <Icon name="facebook" />
            </a>
            <a className="custom-link ms-4" href={config.socialLinks.twitter} rel="noreferrer noopener" target="_blank">
              <Icon name="twitter" />
            </a>
            <a
              className="custom-link ms-4"
              href={config.socialLinks.instagram}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Icon name="instagram" />
            </a>
            <a className="custom-link ms-4" href={config.socialLinks.youtube} rel="noreferrer noopener" target="_blank">
              <Icon name="youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
