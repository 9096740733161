import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { useForm } from 'react-hook-form';
import { chipmunk, displayError, start } from 'utils';
import { AccountSidemenu, ButtonSpinner, InterestSelection, TopSection } from 'components';
import { genderMap, salutations } from 'pages/registration/registration';
import cx from 'classnames';
import { countries } from '@mediafellows/tuco/dist/lib/localization';
import './style.scss';
import { Modal } from 'types';

const getSalutationTitle = (user) => {
  const { gender } = user || {};

  if (!gender) {
    return 'Mr.';
  }

  for (const [key, value] of Object.entries(genderMap)) {
    if (value === gender) {
      return key;
    }
  }
};

export const getDefaultPhone = (user) => user?.phones?.find((p) => p.sequence_number === 1) || '';

export const AccountSettings: React.FC = observer(() => {
  const store = useStore();
  const {
    toastStore,
    modalStore,
    sessionStore: { session, user, loadUser },
  } = useStore();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [interests, setInterests] = useState([]);

  const userDefaults = useMemo(() => {
    return {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      function: user?.function,
      organization_name: user?.organization?.name,
      phone_0_number: getDefaultPhone(user)?.number,
    };
  }, [user]);

  useEffect(() => {
    (async () => {
      await start(store);
    })();
  }, [store]);

  useEffect(() => {
    if (user) {
      reset(userDefaults);
      setInterests(user?.interests || []);
    }
  }, [user, userDefaults, reset]);

  const handleCancel = (e) => {
    e.preventDefault();
    reset(userDefaults);
    setInterests(user?.interests || []);
  };

  const changePassword = async () => {
    const userId = session.user.id;

    modalStore.open(Modal.CHANGE_PASSWORD, { props: { userId } });

    // console.info(res);
  };

  const submitCallback = useCallback(
    async ({ user_salutation, phone_0_number: userPhone, ...rest }): Promise<void> => {
      const finalData = { ...rest, interests, gender: genderMap[user_salutation] };
      setIsLoading(true);

      try {
        await chipmunk.action('um.user', 'member.update', {
          params: { user_id: session.user.id },
          body: finalData,
          ROR: true,
        });

        const phoneId = getDefaultPhone(user)?.id;
        if (phoneId && userPhone) {
          await chipmunk.action('um.user/phone', 'member.update', {
            params: { user_ids: user.id, phone_id: phoneId },
            body: { number: userPhone },
          });
        }

        await loadUser(true);
        toastStore.success('Changes have been saved successfully.');
      } catch {
        toastStore.error('Failed to save changes. Please try again or contact us.');
      } finally {
        setIsLoading(false);
      }
    },
    [session, toastStore, interests, user, loadUser],
  );

  const country = useMemo(() => {
    return countries.find(({ id }) => id === user?.organization?.addresses?.[0]?.country_id)?.name || '-';
  }, [user]);

  if (!user) return null;

  return (
    <>
      <TopSection title="Settings" isSmall={true} />
      <div className="container-fluid--custom">
        <AccountSidemenu />
        <div className="row">
          <div className="col-12 col-md-8">
            <h4 className="mb-4">Account Info</h4>
            <form
              id="accountForm"
              className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5"
              onSubmit={handleSubmit(submitCallback)}
              noValidate={true}
            >
              <div className="col mb-3">
                <label htmlFor="company" className="form-label">
                  Company
                </label>
                <div className="account-settings-edit">
                  <input
                    id="company"
                    placeholder="Ex. Lionsgate"
                    type="text"
                    {...register('organization_name', {
                      required: true,
                      minLength: 2,
                      maxLength: 255,
                    })}
                    className={cx('form-control form-control-lg', { 'is-invalid': errors?.organization_name })}
                  />
                </div>

                {displayError(errors?.organization_name, 'Company')}
              </div>
              <div className="col mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <div className="account-settings-edit">
                  <input
                    id="title"
                    placeholder="Ex. Art director"
                    type="text"
                    {...register('function', {
                      required: true,
                      minLength: 2,
                      maxLength: 128,
                    })}
                    className={cx('form-control form-control-lg', { 'is-invalid': errors?.function })}
                  />
                </div>

                {displayError(errors?.function, 'Job title')}
              </div>
              <div className="col mb-3">
                <label htmlFor="salutation" className="form-label">
                  Salutation
                </label>
                <div className="account-settings-edit">
                  <select
                    id="salutation"
                    defaultValue={getSalutationTitle(user)}
                    className={cx('form-select form-select-lg d-inline-block', {
                      'is-invalid': errors?.user_salutation,
                    })}
                    aria-label="Salutation"
                    {...register('user_salutation')}
                  >
                    {salutations.map((profile) => (
                      <option key={profile} value={profile}>
                        {profile}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col mb-3">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <div className="account-settings-edit">
                  <input
                    id="firstName"
                    placeholder="Ex. John"
                    type="text"
                    {...register('first_name', {
                      required: true,
                      minLength: 2,
                      maxLength: 32,
                    })}
                    className={cx('form-control form-control-lg', { 'is-invalid': errors?.first_name })}
                  />
                </div>

                {displayError(errors?.first_name, 'First name')}
              </div>
              <div className="col mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <div className="account-settings-edit">
                  <input
                    id="lastName"
                    placeholder="Ex. Smith"
                    type="text"
                    {...register('last_name', {
                      required: true,
                      minLength: 2,
                      maxLength: 32,
                    })}
                    className={cx('form-control form-control-lg', { 'is-invalid': errors?.last_name })}
                  />
                </div>
                {displayError(errors?.last_name, 'Last name')}
              </div>
              <div className="col mb-3">
                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <div className="account-settings-edit">
                  <input
                    id="email"
                    placeholder="Ex. johnsmith@isp.com"
                    type="email"
                    {...register('email', {
                      required: true,
                      minLength: 8,
                    })}
                    autoComplete={'email'}
                    className={cx('form-control form-control-lg', { 'is-invalid': errors?.email })}
                  />
                </div>
                {displayError(errors?.email, 'Email')}
              </div>
              <div className="col mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <div className="account-settings-edit">
                  <input
                    id="phone"
                    type="text"
                    {...register('phone_0_number', {
                      pattern: /^[0-9()+\-.]{5,}$/i,
                      required: true,
                    })}
                    className={cx('form-control form-control-lg', { 'is-invalid': errors?.phone_0_number })}
                  />
                </div>
                {displayError(errors?.phone_0_number, 'Phone')}
              </div>
              <div className="col mb-3">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <div className="account-settings-edit">
                  <input
                    id="country"
                    defaultValue={country}
                    className="form-control form-control-lg"
                    type="text"
                    disabled
                  />
                </div>
              </div>
              <div className="col mb-3">
                <button className="btn btn-lg btn-outline-primary" onClick={changePassword}>
                  <ButtonSpinner title="Change Password" isLoading={false} />
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="d-block d-sm-none">
            <InterestSelection state={[interests, setInterests]} />
          </div>
          <div className="col-8 d-none d-sm-block">
            <InterestSelection state={[interests, setInterests]} />
          </div>
        </div>

        <div className="account-settings-hr"></div>

        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center flex-column text-center">
                <button
                  type="submit"
                  form="accountForm"
                  disabled={isLoading}
                  className="btn btn-lg btn-outline-primary"
                >
                  <ButtonSpinner title="Save Changes" isLoading={isLoading} />
                </button>
                <a href="#" className="my-4" onClick={handleCancel}>
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
