import { observable, action, IObservableArray } from 'mobx';
import { each, map, get, filter, reduce, isEmpty } from 'lodash';
import { assetListSchema, productListSchema } from '@mediafellows/tuco/dist/lib/schemas';

import { chipmunk } from 'utils';
import { RootStore } from 'store/root-store';

interface IGetRecommendationOpts {
  recommendationId: string | number;
  entityId?: string | number;
  entityType?: string;
  force?: boolean;
}

export class RecommendationsStore {
  @observable public recommendations = [] as IObservableArray;
  @observable public users = [] as IObservableArray;
  @observable public assets = [] as IObservableArray;
  @observable public products = [] as IObservableArray;
  @observable public productId: number;
  @observable public recommendation;
  @observable public is2FARecoRedirect = false;

  public schemas = {
    assets: assetListSchema,
  };

  private recommendationsPromise: Promise<any>;
  private recommendationPromise: Promise<any>;
  private recommendationsAssetsPromise: Promise<any>;
  private recommendationsProductsPromise: Promise<any>;
  private recommendationsUsersPromise: Promise<any>;
  private root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
  }

  @action.bound
  public async load() {
    return chipmunk.run(
      async (chipmunk) => {
        if (this.recommendationsPromise) return this.recommendationsPromise;

        const promise = async () => {
          const res = await chipmunk.action('mc.email', 'user_query', {
            params: {
              sort: 'created_at',
              order: 'desc',
            },
            schema: `
            id, created_at, expires_at, asset_ids, product_ids, views_left,
            campaign { twofa, permissions, protection_levels, views_granted, message, subject, target, from_user_id, target_group_id },
          `,
          });

          // do not show expired recommendations
          const filteredRecommendation = filter(
            res.objects,
            (r) => r.views_left !== -1 || new Date(r.expires_at) < new Date(),
          );

          // this.withStore('profileStore', (store: ProfileStore) => {
          //   store.setStats('recommendations', filteredRecommendation.length);
          // });

          this.recommendations.replace(filteredRecommendation);
        };

        return (this.recommendationsPromise = promise());
      },
      () => {
        this.recommendationsPromise = null;
        this.root.toastStore.error('Failed to load recommendations');
      },
    );
  }

  @action.bound
  public async getRecommendation(opts: IGetRecommendationOpts) {
    return chipmunk.run(
      async (chipmunk) => {
        if (!opts.force && this.recommendationPromise) return this.recommendationPromise;

        const promise = async () => {
          const { object } = await chipmunk.action('mc.email', 'view', {
            params: {
              email_id: opts.recommendationId,
              entity_id: opts.entityId,
              entity_type: opts.entityType,
            },
            schema: `
            id, created_at, expires_at, asset_ids, product_ids, views_left,email_address,
            campaign { permissions, protection_levels, views_granted, message, subject, target, from_user_id, target_group_id, require_login },
          `,
          });

          return (this.recommendation = object);
        };

        return (this.recommendationPromise = promise());
      },
      () => {
        this.recommendationPromise = null;
        this.root.toastStore.error('Failed to get a recommendation');
      },
    );
  }

  @action.bound
  public async init(showAll = false) {
    await Promise.all([this.getRecommendationUsers(showAll), this.getRecommendationProducts(showAll)]);
  }

  @action.bound
  public async getRecommendationUsers(showAll = false) {
    return chipmunk.run(
      async (chipmunk) => {
        if (this.recommendationsUsersPromise && !showAll) return this.recommendationsUsersPromise;
        const usersIds = showAll
          ? map(this.recommendations, (r) => r.campaign.from_user_id)
          : get(this.recommendation, 'campaign.from_user_id');

        if (!usersIds || (showAll && isEmpty(usersIds))) return;

        const promise = async () => {
          const res = await chipmunk.action('um.user', 'get', {
            params: { user_ids: usersIds },
            schema: `id, first_name, last_name, email`,
          });

          this.users.replace(res.objects);
        };

        return (this.recommendationsUsersPromise = promise());
      },
      () => {
        this.recommendationsUsersPromise = null;
        this.root.toastStore.error('Failed to get a recommendation users');
      },
    );
  }

  @action.bound
  public async getRecommendationAssets(showAll = false, schema = '') {
    return chipmunk.run(
      async (chipmunk) => {
        if (this.recommendationsAssetsPromise && !showAll) return this.recommendationsAssetsPromise;

        const assetIds = showAll
          ? this.flattenArray(map(this.recommendations, 'asset_ids'))
          : this.recommendation?.asset_ids;

        if (isEmpty(assetIds)) {
          return;
        }

        const promise = async () => {
          const res = await chipmunk.action('am.asset', 'get', {
            params: {
              asset_ids: assetIds,
            },
            schema: schema || this.schemas.assets,
          });

          this.assets.replace(res.objects);
        };

        return (this.recommendationsAssetsPromise = promise());
      },
      () => {
        this.recommendationsAssetsPromise = null;
        this.root.toastStore.error('Failed to get a recommendation assets');
      },
    );
  }

  @action.bound
  public async getRecommendationProducts(showAll = false) {
    return chipmunk.run(
      async (chipmunk) => {
        if (this.recommendationsProductsPromise && !showAll) return this.recommendationsProductsPromise;

        const productIds = showAll
          ? this.flattenArray(map(this.recommendations, 'product_ids'))
          : this.recommendation?.product_ids;

        if (isEmpty(productIds)) {
          return;
        }

        const promise = async () => {
          const res = await chipmunk.action('pm.product', 'get', {
            params: {
              product_ids: productIds,
            },
            schema: productListSchema
              .replace('seasons_count,', `seasons_count, preview_asset { elements},`)
              .replace('product_state,', 'product_state,product_type,'),
          });

          this.products.replace(res.objects);
        };

        return (this.recommendationsProductsPromise = promise());
      },
      () => {
        this.root.toastStore.error('Failed to get a recommendation products');
      },
    );
  }

  // for some reason lodash flatten & compact does not work ???
  private flattenArray(arrays) {
    const newArray = reduce(
      arrays,
      (result, value) => {
        each(value, (v) => {
          result.push(v);
        });
        return result;
      },
      [],
    );

    return newArray;
  }

  public setSchemas(schemas) {
    each(schemas, (schema, name) => (this.schemas[name] = schema));
  }

  @action.bound
  public resetPromises() {
    this.recommendationsPromise = null;
    this.recommendationPromise = null;
    this.recommendationsAssetsPromise = null;
    this.recommendationsProductsPromise = null;
    this.recommendationsUsersPromise = null;
  }
}
