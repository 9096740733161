import { Link, useLocation } from 'react-router-dom';
import { Routes } from 'routes';
import { Icon } from 'components';
import cx from 'classnames';
import React from 'react';
import './style.scss';
import { isArray } from 'lodash';
import { isEqualArray } from 'utils';
import { observer } from 'mobx-react-lite';
import { ActiveFiltersNames, MAIN_CATEGORY } from 'types';
import { getSelectedGenres } from 'utils/get-selected-genres';
import { useStore } from 'store';
import { useCategoriesStore } from 'store/categories-store/categories-store';
import { useBasketsStore } from 'store/baskets-store/baskets-store';
import { useSearchStore } from 'store/search-store/search-store';

interface Breadcrumb {
  name: string;
  url?: string;
}

interface ITopSection {
  className?: string;
  title: string;
  extraTitle?: any;
  breadcrumbs?: Breadcrumb[];
  layoutControl?: boolean;
  isDashboard?: boolean;
  subtitle?: string;
  isSelectionPage?: boolean;
  onFiltersClick?: () => void;
  onClearClick?: () => void;
  customActions?: any;
  subTitle?: any;
  rootBreadcrumb?: any;
  isSmall?: boolean;
  store?: 'search' | 'baskets' | 'category';
}

const getStore = (store) => {
  if (store === 'category') {
    return useCategoriesStore;
  }

  if (store === 'baskets') {
    return useBasketsStore;
  }

  return useSearchStore;
};

const TopSection: React.FC<ITopSection> = observer((props) => {
  const {
    title,
    extraTitle,
    subTitle,
    className,
    breadcrumbs,
    onFiltersClick,
    onClearClick,
    customActions,
    rootBreadcrumb,
    isSmall,
    store,
  } = props;
  const {
    basicStore: { categories },
  } = useStore();
  const { pathname } = useLocation();
  const rootTitle = pathname.includes(Routes.MOVIES) ? 'Movies' : 'Television';
  const route = pathname.includes(Routes.MOVIES) ? Routes.MOVIES : Routes.TV;

  const activeStore = getStore(store);
  const activeFilters = activeStore((state) => state.activeFilters);
  const defaultFilters = activeStore((state) => state.defaultFilters);
  const filters = activeStore((state) => state.filters);
  const hasFilters = store && filters;

  const selectedGenres = filters ? getSelectedGenres(filters, categories) : [];

  return (
    <section className={cx('top-section pt-13 pt-lg-13 pb-3 pb-lg-3', className)}>
      <div className="container-fluid--custom position-relative">
        {!!breadcrumbs?.length && (
          <div className="row">
            <div className="col-12 d-flex align-items-center mb-3">
              <>
                <Link to={rootBreadcrumb?.[1] || route}>{rootBreadcrumb?.[0] || rootTitle}</Link>
                <div>&nbsp;/&nbsp;</div>
              </>

              {breadcrumbs?.map((item, index) => {
                if (index === breadcrumbs.length - 1) {
                  return (
                    <span className="top-section__breadcrumb" key={index}>
                      {item?.name}
                    </span>
                  );
                }

                return (
                  <React.Fragment key={`${index}-icon`}>
                    <Link to={item?.url} key={index}>
                      {item?.name}
                    </Link>
                    <div>&nbsp;/&nbsp;</div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
        <div
          className={cx({
            'row row-cols-1': !filters && !customActions,
            'row row-cols-1 row-cols-lg-2': filters || customActions,
          })}
        >
          <div className="col">
            <div className="d-flex flex-column">
              <div className="top-section__header-columns">
                {isSmall ? (
                  <h3
                    className={cx(
                      'heading-spacing d-inline-block text-capitalize',
                      { 'mb-0': !subTitle },
                      { 'mb-3': subTitle },
                    )}
                  >
                    {title}
                  </h3>
                ) : (
                  <h1
                    className={cx(
                      'heading-spacing d-inline-block text-capitalize',
                      { 'mb-0': !subTitle },
                      { 'mb-3': subTitle },
                    )}
                  >
                    {title}
                  </h1>
                )}
                {Boolean(extraTitle) && <div>{extraTitle}</div>}
              </div>
              {subTitle}
            </div>
          </div>

          {(customActions || hasFilters) && (
            <div className="col d-flex justify-content-end flex-column mt-4 mt-lg-0">
              <div className="row">
                {customActions && <div className="col-12">{customActions}</div>}
                {pathname !== Routes.SAVED_LISTS && filters && (
                  <div className="col d-flex justify-content-end flex-column">
                    {!!activeFilters.length && (
                      <div className="d-flex align-items-start align-items-lg-end justify-content-end small mb-2 flex-column">
                        {Object.keys(filters).map((key) => {
                          if (key === 'q') return;
                          const filter = filters[key].value;
                          const arr = isArray(filter);

                          if ((arr && !filter.length) || !filter) {
                            return null;
                          }

                          const isPairFilter = key === 'duration' || key === 'year_of_production';
                          if (isPairFilter && !isEqualArray(filter, defaultFilters[key].value)) {
                            return (
                              <span key={key}>
                                <span className="text-capitalize me-2 fw-bolder">{ActiveFiltersNames[key]}:</span>
                                <span>
                                  {filter[0]} - {filter[1]}
                                </span>
                              </span>
                            );
                          }

                          if (key === 'category_ids') {
                            return (
                              <span key={key}>
                                <span className="text-capitalize me-2 fw-bolder">{ActiveFiltersNames[key]}:</span>
                                {selectedGenres.map((item, i) => {
                                  const isLast = i === selectedGenres.length - 1;
                                  return (
                                    <div
                                      className={cx('d-inline-block text-capitalize', { 'me-2': !isLast })}
                                      key={item}
                                    >
                                      {item}
                                    </div>
                                  );
                                })}
                              </span>
                            );
                          }

                          if (isPairFilter) return null;

                          if (isArray(filter)) {
                            return (
                              <span key={key}>
                                <span className="text-capitalize me-2 fw-bolder">{ActiveFiltersNames[key]}:</span>
                                {filter.map((item, i) => {
                                  const isLast = i === filter.length - 1;

                                  return (
                                    <span className={cx({ 'me-2': !isLast })} key={i}>
                                      {item}
                                    </span>
                                  );
                                })}
                              </span>
                            );
                          }

                          const isCapitalKey = key === 'product_type';
                          const customValue = filter === MAIN_CATEGORY.TV ? 'Television' : filter;

                          return (
                            <span key={key}>
                              <span className="text-capitalize me-2 fw-bolder">{ActiveFiltersNames[key]}:</span>
                              <div className={cx('d-inline-block', { 'text-capitalize': isCapitalKey })}>
                                {customValue}
                              </div>
                            </span>
                          );
                        })}
                      </div>
                    )}
                    <div className="d-flex align-items-center justify-content-start justify-content-lg-end">
                      {!!activeFilters.length && (
                        <div className="custom-link pointer me-5 d-flex align-items-center" onClick={onClearClick}>
                          Clear Filters
                          <Icon name={'x'} className="ms-2" width={16} height={16} />
                        </div>
                      )}
                      <div className="custom-link pointer me-5 d-flex align-items-center d-none">
                        Genres
                        <svg
                          width="7"
                          height="6"
                          viewBox="0 0 7 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="top-section__chevron"
                        >
                          <path d="M3.82227 5.5L0.791177 0.249999L6.85335 0.25L3.82227 5.5Z" fill="white" />
                        </svg>
                      </div>
                      <div className="custom-link pointer d-flex align-items-center" onClick={onFiltersClick}>
                        Filter
                        <svg
                          width="7"
                          height="6"
                          viewBox="0 0 7 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="top-section__chevron"
                        >
                          <path d="M3.82227 5.5L0.791177 0.249999L6.85335 0.25L3.82227 5.5Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

export default TopSection;
