import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { useStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Routes } from 'routes';

const PublicRoute = observer(({ children, ...rest }) => {
  const { sessionStore, basicStore } = useStore();
  const session = sessionStore.session;

  useEffect(() => {
    sessionStore.loadSession();
  }, [sessionStore, basicStore]);

  if (!sessionStore.initialLoadDone) {
    return null;
  }

  const handleRender = () => {
    if (!session || session.isPublic || session.isRecommendation) {
      return children;
    }

    return <Redirect to={Routes.HOME} />;
  };

  return <Route {...rest} render={handleRender} />;
});

export default React.memo(PublicRoute);
