import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import React, { useEffect } from 'react';
import { Modal as BSModal } from 'bootstrap';
import { Modal } from 'types';
import { useStore } from 'store';
import cx from 'classnames';
import {
  EditListModal,
  ChangePasswordModal,
  SaveToListModal,
  PreviewAssetModal,
  ConfirmationModal,
  SearchModal,
} from '../index';
import { useLocation } from 'react-router-dom';

const ModalContent = ({ name, ...rest }) => {
  switch (name) {
    case Modal.EDIT_LIST:
      return <EditListModal {...rest} />;
    case Modal.CHANGE_PASSWORD:
      return <ChangePasswordModal userId={rest.userId} />;
    case Modal.SAVE_TO_LIST:
      return <SaveToListModal {...rest} />;
    case Modal.PREVIEW_ASSETS:
      return <PreviewAssetModal product={rest.product} />;
    case Modal.CONFIRMATION_MODAL:
      return <ConfirmationModal {...rest} />;
    case Modal.SEARCH_MODAL:
      return <SearchModal {...rest} />;
    default:
      return null;
  }
};

const ModalManager = observer(() => {
  const store = useStore();
  const {
    modalStore: { updateModals, modals, hideCallback, hide, setHideCallback },
  } = store;

  const location = useLocation();

  const container = React.useRef(null);

  useEffect(() => {
    const containerEl = container.current;

    const handleHidden = (e) => {
      const id = e.target.id;
      const newModal = modals.filter((t) => t.id !== id);
      updateModals(newModal);
      hideCallback?.();
      setHideCallback(null);
    };
    const handleModalOpen = () => {
      const interval = setInterval(() => {
        const el = document.querySelector('.modal-backdrop');
        if (el) {
          el?.classList.add('modal-backdrop-open');
          clearInterval(interval);
        }
      }, 100);
    };

    const handleModalClose = () => {
      const el = document.querySelector('.modal-backdrop');
      el?.classList.remove('modal-backdrop-open');
    };

    containerEl.addEventListener('show.bs.modal', handleModalOpen);
    containerEl.addEventListener('hide.bs.modal', handleModalClose);
    containerEl.addEventListener('hidden.bs.modal', handleHidden);
    return () => {
      containerEl.removeEventListener('show.bs.modal', handleModalOpen);
      containerEl.removeEventListener('hide.bs.modal', handleModalClose);
      containerEl.removeEventListener('hidden.bs.modal', handleHidden);
    };
  }, [modals, updateModals, hideCallback, setHideCallback]);

  useEffect(() => {
    autorun(() => {
      let hasUpdate = false;

      const newModals = modals.map((modal) => {
        const modalEl = modal.ref.current;

        if (!modal.bootstrapModal && modalEl) {
          modal.bootstrapModal = new BSModal(modalEl, modal?.modalOptions);
          modal.bootstrapModal.show();
          hasUpdate = true;
        }

        return modal;
      });

      if (hasUpdate) {
        updateModals(newModals);
      }
    });
  }, [modals, updateModals]);

  useEffect(() => {
    if (modals.length) {
      hide();
    }
  }, [modals, location, hide]);

  return (
    <div ref={container}>
      {modals.map(({ name, props, id, ref, sizeClass }) => {
        return (
          <div
            className={`modal fade modal--${name}`}
            ref={ref}
            id={id}
            key={id}
            tabIndex={-1}
            role="dialog"
            aria-labelledby={`${id}Label`}
            aria-hidden="true"
          >
            <div className={cx('modal-dialog modal-dialog-centered', sizeClass)} role="document">
              <div className="modal-content">
                <ModalContent id={id} name={name} {...props} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default ModalManager;
